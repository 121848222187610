<template>

    <div class="th-menu-wrapper">
        <div class="th-menu-area text-center"><button class="th-menu-toggle"><i class="fal fa-times"></i></button>
            <div class="mobile-logo"><a href="index"><img src="assets/img/logo.png" alt="安可控"></a></div>
            <div class="th-mobile-menu">
                <ul>
                    <li class="active"><a href="index">网站首页</a></li>

                    <li class="menu-item-has-children"><a href="#">解决方案</a>
                        <ul class="sub-menu">
                            <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                            <li><a href="solution2.html">智慧安防解决方案</a></li>
                            <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                            <li><a href="solution3.html">智慧工地解决方案</a></li>
                            <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                            <li><a href="solution5.html">智能两票解决方案</a></li>
                            <li><a href="solution6.html">智慧外包解决方案</a></li>
                            <li><a href="solution7.html">双重预控智能管理方案</a></li>
                            <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                            <li><a href="solution9.html">智能锁控解决方案</a></li>
                            <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                            <li><a href="solution11.html">设备缺陷智能分析解决方案</a></li>
                            <li><a href="solution12.html">在线考试管理解决方案</a></li>
                            <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                        </ul>
                    </li>
                    <li class="menu-item-has-children"><a href="#">产品与服务</a>
                        <ul class="sub-menu">
                            <li> <strong>基础模块</strong>
                                <a href="product1.html">生产任务管理</a>
                                <a href="product2.html">设备管理</a>
                                <a href="product3.html">运行管理</a>
                                <a href="product4.html">安全管理</a>
                                <a href="product5.html">班组管理</a>
                                <a href="product6.html">物资管理</a>
                                <a href="product7.html">问题管理</a>
                                <a href="product8.html">在线考试管理</a>
                                <a href="product9.html">职业健康管理</a>
                                <a href="product10.html">标准制度管理</a>
                                <a href="product11.html">案例库管理</a>
                            </li>
                            <li> <strong>增值服务</strong>
                                <a href="product12.html">智能两票管理</a>
                                <a href="product13.html">智慧外包管理</a>
                                <a href="product14.html">智能点检管理</a>
                                <a href="product15.html">全员绩效管理</a>
                                <a href="product16.html">风险评估管理</a>
                                <a href="product17.html">安全生产责任制</a>
                                <strong>特色模块</strong>
                                <a href="product18.html">AI视频分析及预警</a>
                                <a href="product19.html">实时定位和监控</a>
                                <a href="product20.html">智能门禁管理</a>
                                <a href="product21.html">智能锁控管理</a>
                                <a href="product22.html">智能穿戴设备</a>
                                <a href="product23.html">数据采集管理</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="cooperation.html">商业合作</a></li>
                    <li><a href="news">新闻动态</a></li>
                    <li><a href="about.html">关于我们</a></li>
                </ul>
            </div>
        </div>
    </div>
    <header class="th-header header-layout3">
        <div class="container">
            <div class="header-top">
                <div class="row justify-content-center justify-content-lg-between align-items-center gy-2">
                    <div class="col-auto d-none d-lg-block">
                        <div class="header-links">
                            <ul>

                                <li class="d-none d-xl-inline-block"><i
                                        class="far fa-location-dot"></i>长沙市雨花区洞井街道湘府东路二段160号水映青山园5栋（综合楼）901</li>
                                <li><i class="far fa-phone"></i><a href="tel:4007059928">400-705-9928</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="header-links header-right">
                            <ul>
                                <li>
                                    <div class="header-social"><span class="social-title">社交媒体:</span> <a
                                            href="about.html#Contact"><i class="fab fa-weibo"></i></a> <a
                                            href="about.html#Contact"><i class="fab fa-weixin"></i></a></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sticky-wrapper">
            <div class="container">
                <div class="menu-area">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto">
                            <div class="header-logo"><a href="index"><img src="assets/img/logo.png"
                                        alt="安可控"></a></div>
                        </div>
                        <div class="col-auto me-lg-auto">
                            <nav class="main-menu d-none d-lg-inline-block">
                                <ul>
                                    <li class="active"><a href="index">网站首页</a></li>
                                    <li class="menu-item-has-children"><a href="#">解决方案</a>
                                        <ul class="sub-menu">
                                            <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                                            <li><a href="solution2.html">智慧安防高效解决方案</a></li>
                                            <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                                            <li><a href="solution3.html">智慧工地一体解决方案</a></li>
                                            <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                                            <li><a href="solution5.html">智能两票综合解决方案</a></li>
                                            <li><a href="solution6.html">智慧外包管理解决方案</a></li>
                                            <li><a href="solution7.html">双重预控智能管理方案</a></li>
                                            <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                                            <li><a href="solution9.html">智能锁控集成解决方案</a></li>
                                            <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                                            <li><a href="solution11.html">设备缺陷智能分析方案</a></li>
                                            <li><a href="solution12.html">在线考试管理解决方案</a></li>
                                            <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                                        </ul>
                                    </li>
                                    <li class="menu-item-has-children"><a href="#">产品与服务</a>
                                        <ul class="sub-menu submenurow">
                                            <li> <strong>基础模块</strong>
                                                <a href="product1.html">生产任务管理</a>
                                                <a href="product2.html">设备管理</a>
                                                <a href="product3.html">运行管理</a>
                                                <a href="product4.html">安全管理</a>
                                                <a href="product5.html">班组管理</a>
                                                <a href="product6.html">物资管理</a>
                                                <a href="product7.html">问题管理</a>
                                                <a href="product8.html">在线考试管理</a>
                                                <a href="product9.html">职业健康管理</a>
                                                <a href="product10.html">标准制度管理</a>
                                                <a href="product11.html">案例库管理</a>
                                            </li>
                                            <li> <strong>增值服务</strong>
                                                <a href="product12.html">智能两票管理</a>
                                                <a href="product13.html">智慧外包管理</a>
                                                <a href="product14.html">智能点检管理</a>
                                                <a href="product15.html">绩效管理</a>
                                                <a href="product16.html">风险评估</a>
                                                <a href="product17.html">责任制评价</a>
                                                <strong>特色模块</strong>
                                                <a href="product18.html">AI视频分析及预警</a>
                                                <a href="product19.html">实时定位和监控</a>
                                                <a href="product20.html">智能门禁管理</a>
                                                <a href="product21.html">智能锁控管理</a>
                                                <a href="product22.html">智能穿戴设备</a>
                                                <a href="product23.html">数据采集管理</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="cooperation.html">商业合作</a></li>
                                    <li><a href="news">新闻动态</a></li>
                                    <li><a href="about.html">关于我们</a></li>
                                </ul>
                            </nav><button type="button" class="th-menu-toggle d-block d-lg-none"><i
                                    class="far fa-bars"></i></button>
                        </div>
                        <div class="col-auto d-none d-xl-block">
                            <div class="header-button"> <a href="about.html" class="th-btn style3 ml-15">联系我们 <i
                                        class="fas fa-arrow-right ms-1"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <a-carousel arrows dots="false">
        <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
            </div>
        </template>
        <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
            </div>
        </template>
        <div class="carousel1">
            <h3>
                <p>More intelligent, more efficient, more secure, more reliable</p>更智能 &nbsp;更高效 &nbsp;更安全 &nbsp;更可靠
                <div><a href="solution1.html" class="th-btn style3 ml-15">了解更多 <i
                            class="fas fa-arrow-right ms-1"></i></a></div>
            </h3>
        </div>
        <div class="carousel2">
            <h3>
                <p>Trustworthy expert in intelligent security control</p>值得信赖的智能安全管控专家
                <div><a href="solution1.html" class="th-btn style3 ml-15">了解更多 <i
                            class="fas fa-arrow-right ms-1"></i></a></div>
            </h3>
        </div>
        <div class="carousel3">
            <h3>
                <p>Security management has never been so simple and efficient!</p> 安全管理，从未如此简单高效！
                <div><a href="solution1.html" class="th-btn style3 ml-15">了解更多 <i
                            class="fas fa-arrow-right ms-1"></i></a></div>
            </h3>
        </div>
        <div class="carousel4">
            <h3>
                <p>Promote customers to achieve high-quality development and high-level security, and create a bright
                    future
                    together!</p>推动客户实现高质量发展和高水平安全，共创美好未来！
                <div><a href="solution1.html" class="th-btn style3 ml-15">了解更多 <i
                            class="fas fa-arrow-right ms-1"></i></a></div>
            </h3>
        </div>
    </a-carousel>


    <!-- <div class="container">
        <div class="feature-area-2">
            <div class="row th-carousel g-0 justify-content-center" data-slide-show="3" data-lg-slide-show="2"
                data-md-slide-show="2">
                <div class="col-lg-4 col-md-6">
                    <div class="feature-card2 hover-item">
                        <div class="feature-card2-icon"><img src="assets/img/icon/feature-icon2-1.svg" alt="Icon"></div>
                        <div class="media-body">
                            <h3 class="box-title"><a href="service.html">基础模块</a></h3>
 <p class="feature-card2-text" style=" text-align: justify; line-height: 22px;">生产任务管理，设备管理，运行管理，安全管理，班组管理，物资管理，问题管理，在线考试管理，职业健康管理，标准制度管理，案例库管理。</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="feature-card2 hover-item item-active">
                        <div class="feature-card2-icon"><img src="assets/img/icon/feature-icon2-2.svg" alt="Icon"></div>
                        <div class="media-body">
                            <h3 class="box-title"><a href="service.html">增值服务</a></h3>
                            <p class="feature-card2-text">智能两票管理，智慧外包管理，智能点检管理，绩效管理，风险评估，责任制评价。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="feature-card2 hover-item">
                        <div class="feature-card2-icon"><img src="assets/img/icon/feature-icon2-3.svg" alt="Icon"></div>
                        <div class="media-body">
                            <h3 class="box-title"><a href="service.html">特色模块</a></h3>
                            <p class="feature-card2-text">AI视频分析及预警，实时定位和监控，智能门禁管理，智能锁控管理，智能穿戴，数据采集管理。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="th-container6 space-extra-top space-bottom" id="about">
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="img-box11">
                        <div class="img1"><img class="tilt-active" src="assets/img/update1/normal/about_3_1.jpg"
                                alt="About"></div>
                        <div class="text-end">
                            <div class="img2"><img class="tilt-active" src="assets/img/update1/normal/about_3_2.jpg"
                                    alt="About"></div>
                        </div>
                        <div class="shape1"><img src="assets/img/update1/shape/about_3_1.png" alt="About"></div>
                        <div class="shape2"><img src="assets/img/update1/shape/about_3_2.png" alt="About"></div>
                    </div>
                </div>
                <div class="col-xl-6 ps-xxl-5">
                    <div class="title-area mb-30">
                        <div class="shadow-title">安可控</div><span class="sub-title6">安可控<span class="shape right"><span
                                    class="dots"></span></span></span>
                        <h2 class="sec-title">科技推动进步<br>创新引领发展</h2>
                    </div>
                    <p class="mt-n2 mb-4">
                        安可控是一家致力于推动客户实现高质量发展和高水平安全的智能安全管控专家。安可控始终以先进的人工智能、大数据和物联网技术为核心动力，致力于以优质的产品和服务推动企业安全管理升级、智能化改造、经济增长和社会进步。
                    </p>
                    <div class="about-feature">
                        <div class="about-feature_icon"><img src="assets/img/update1/icon/a_feature_1_1.svg" alt="icon">
                        </div>
                        <div>
                            <h3 class="about-feature_title">人工智能 大数据 物联网</h3>
                            <p class="about-feature_text">
                                安可控始终以先进的人工智能、大数据和物联网技术为核心动力，致力于以优质的产品和服务推动企业安全管理升级、智能化改造、经济增长和社会进步。<a
                                    href="about.html">了解更多<i class="fas fa-arrow-right ms-2"></i></a></p>
                        </div>
                    </div>
                    <div class="about-feature">
                        <div class="about-feature_icon"><img src="assets/img/update1/icon/a_feature_1_2.svg" alt="icon">
                        </div>
                        <div>
                            <h3 class="about-feature_title">安全生产 智慧安防 双重预控 </h3>
                            <p class="about-feature_text">我们通过全面的安全生产智能管控平台和智慧安防系统，为企业提供全方位的安全解决方案，助力客户实现高质量发展和长期价值创造。<a
                                    href="about.html">了解更多<i class="fas fa-arrow-right ms-2"></i></a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <section class="space overflow-hidden" data-bg-src="assets/img/update1/bg/service_bg_3.jpg" id="product-home">
        <div class="container">
            <div class="title-area text-center"><span class="sub-title6 justify-content-center"><span
                        class="shape left"><span class="dots"></span></span> Product Introduction <span
                        class="shape right"><span class="dots"></span></span></span>
                <h2 class="sec-title">产品介绍</h2>
            </div>
            <div class="row gx-0">
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_1.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                安全生产智能管控平台</h3>
                            <p class="service-list_text">
                                融合了人工智能、大数据和物联网技术，创新性地实现了安全生产和价值创造的全方位智能化管理。平台通过实时数据采集和智能分析，提供高效、可靠的风险预警和管控方案，优化业务流程，提高生产效率，助力企业在保障高水平安全的同时，实现可持续的价值创造，推动高质量发展。
                            </p><a href="solution1.html" class="service-list_btn"><i
                                    class="fas fa-arrow-right icon-btn"></i>了解更多</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_2.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                智慧工地管控平台</h3>
                            <p class="service-list_text">
                                通过数字化和智能化手段，实现对施工现场全方位、全天候的监控与管理，从人员资质、安全培训、高风险作业、重点人员监督到位、到风险智能识别、安全态势智能感知，确保更智能、更规范、更高效、更安全的施工环境，让安全管理从未如此简单！
                            </p><a href="solution3.html" class="service-list_btn"><i
                                    class="fas fa-arrow-right icon-btn"></i>了解更多</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_3.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                风机智慧定检管控平台</h3>
                            <p class="service-list_text">
                                通过集成物联网、5G+、无人机、大数据和人工智能技术，实现了风机定检全生命周期的标准化、过程化、实时化和痕迹化管理，并利用AI大模型智能识别预警风机缺陷，有效提升了风机定检的质量和效率，确保风机运行的安全、稳定和高效。
                            </p><a href="solution4.html" class="service-list_btn"><i
                                    class="fas fa-arrow-right icon-btn"></i>了解更多</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_4.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                智慧安防系统</h3>
                            <p class="service-list_text">
                                融合了AI、大数据和物联网技术，创新实现了全方位的安全防护。系统具备实时风险识别、智能预警和高效应急处理能力，大幅提升了安全管理水平。凭借领先的技术优势和卓越的性能，智慧安防系统在保障企业安全方面树立了行业标杆。
                            </p><a href="solution2.html" class="service-list_btn"><i
                                    class="fas fa-arrow-right icon-btn"></i>了解更多</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_6.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                双重预控智能管理平台</h3>
                            <p class="service-list_text">
                                旨在通过融合人工智能、大数据分析和移动互联技术，实现风险动态识别与评估，对安全风险分级管控与隐患排查治理进行全过程数字化、智能化管理，在大幅减轻工作量的同时，保障企业安全生产和高质量发展。
                            </p><a href="solution7.html" class="service-list_btn"><i
                                    class="fas fa-arrow-right icon-btn"></i>了解更多</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 service-list-wrap">
                    <div class="service-list">
                        <div class="service-list_icon"><img src="assets/img/update1/icon/service_4_5.svg" alt="Icon">
                        </div>
                        <div class="service-list_content">
                            <h3 class="service-list_title box-title" data-bs-toggle="modal"
                                data-bs-target="#serviceModal">
                                更多产品</h3>
                            <p class="service-list_text">更多产品正在设计研发中，敬请期待......</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-5"><a href="#solution-home" class="th-btn style-new">了解解决方案</a></div>
        </div>
    </section>



    <section class="space-top" id="solution-home">
        <div class="container" data-pos-space="#about-sec" data-sec-space="margin-bottom" data-margin-bottom="100px">
            <div class="title-area text-center">
                <div class="shadow-title">解决方案</div><span class="sub-title6 justify-content-center"><span
                        class="shape left"><span class="dots"></span></span> 解决方案一 <span class="shape right"><span
                            class="dots"></span></span></span>
                <h2 class="sec-title">安全生产智能管控方案</h2>
            </div>
            <div class="row slider-shadow th-carousel" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                data-xs-slide-show="1" data-arrows="true">

                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_2.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution1.html">智能风险预警</a>
                            </h3>
                            <p class="service-box2_text">
                                利用AI技术进行数据分析和风险识别，提供即时预警和处理建议，有效防范潜在安全隐患。
                            </p>
                        </div><a href="solution1.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_3.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution1.html">设备全生命周期管理</a>
                            </h3>
                            <p class="service-box2_text">
                                实现设备从采购、运行到维护的全生命周期管理，确保设备健康状态和高效运行，降低维护成本。
                            </p>
                        </div><a href="solution1.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_4.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution1.html">安全态势一体化感知
                                </a>
                            </h3>
                            <p class="service-box2_text">构建全域安全态势感知平台，实时监控现场作业、人员分布和设备状态，实现全方位的安全管控。
                            </p>
                        </div><a href="solution1.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_1.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution1.html">应急响应和处置</a>
                            </h3>
                            <p class="service-box2_text">
                                建立智能应急响应系统，快速识别和处理突发事件，保障人员安全和生产连续性。
                            </p>
                        </div><a href="solution1.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>

    </section>


    <section class="bg-smoke space overflow-hidden" data-bg-src="assets/img/update1/bg/service_bg_1.png"
        id="solution-home2">
        <div class="container">
            <div class="row justify-content-lg-between align-items-end">
                <div class="col-lg-7 mb-n2 mb-lg-0">
                    <div class="title-area"><span class="sub-title6">解决方案二 <span class="shape right"><span
                                    class="dots"></span></span></span>
                        <h2 class="sec-title">智慧安防高效解决方案</h2>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="sec-btn"><a href="solution2.html" class="th-btn">查看详情<i
                                class="fas fa-arrow-right ms-2"></i></a></div>
                </div>
            </div>
            <div class="row slider-shadow th-carousel dot-style3" data-slide-show="4" data-lg-slide-show="3"
                data-md-slide-show="2" data-sm-slide-show="1" data-dots="false" data-xl-dots="false"
                data-ml-dots="false" data-lg-dots="false" data-md-dots="false">
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-card2">
                        <div class="service-card2_img"><img src="assets/img/update1/service/service_1_2_v2.jpg"
                                alt="service image"></div>
                        <div class="service-card2_content" data-bg-src="assets/img/update1/bg/bg_pattern_5.png">
                            <h3 class="service-card2_title"><a href="solution2.html">实时定位监控</a>
                            </h3>
                            <p class="service-card2_text">
                                利用先进的定位技术，实时跟踪和监控人员、设备和车辆的位置，确保在发生紧急情况时能够快速响应和处理。
                            </p>
                            <div class="service-card2_icon"><img src="assets/img/update1/icon/service_1_1.svg"
                                    alt="Icon">
                            </div>
                        </div><a href="solution2.html" class="service-card2_btn"><i
                                class="far fa-long-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-card2">
                        <div class="service-card2_img"><img src="assets/img/update1/service/service_1_1_v2.jpg"
                                alt="service image"></div>
                        <div class="service-card2_content" data-bg-src="assets/img/update1/bg/bg_pattern_5.png">
                            <h3 class="service-card2_title"><a href="solution2.html">智能作业管控</a></h3>
                            <p class="service-card2_text">
                                通过AI视频分析和行为识别技术实时监控作业计划和过程，识别并预警违章行为，确保作业安全，并与智能两票、三讲一落实、日计划管理紧密结合，实现全流程管控。
                            </p>
                            <div class="service-card2_icon"><img src="assets/img/update1/icon/service_1_2.svg"
                                    alt="Icon">
                            </div>
                        </div><a href="solution2.html" class="service-card2_btn"><i
                                class="far fa-long-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-card2">
                        <div class="service-card2_img"><img src="assets/img/update1/service/service_1_3_v2.jpg"
                                alt="service image"></div>
                        <div class="service-card2_content" data-bg-src="assets/img/update1/bg/bg_pattern_5.png">
                            <h3 class="service-card2_title"><a href="solution2.html">全方位安全感知</a>
                            </h3>
                            <p class="service-card2_text">
                                结合传感器、摄像头和其他智能设备，构建全方位、多层次的安全感知体系，实现全域安全态势的实时掌控。

                            </p>
                            <div class="service-card2_icon"><img src="assets/img/update1/icon/service_1_3.svg"
                                    alt="Icon">
                            </div>
                        </div><a href="solution2.html" class="service-card2_btn"><i
                                class="far fa-long-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-card2">
                        <div class="service-card2_img"><img src="assets/img/update1/service/service_1_4_v2.jpg"
                                alt="service image"></div>
                        <div class="service-card2_content" data-bg-src="assets/img/update1/bg/bg_pattern_5.png">
                            <h3 class="service-card2_title"><a href="solution2.html">应急预警和处置</a>
                            </h3>
                            <p class="service-card2_text f14">
                                内置智能应急预警系统，能够在发生紧急情况时，快速通知相关人员并自动采取应急措施，确保人员和资产的安全。

                            </p>
                            <div class="service-card2_icon"><img src="assets/img/update1/icon/service_1_4.svg"
                                    alt="Icon">
                            </div>
                        </div><a href="solution2.html" class="service-card2_btn"><i
                                class="far fa-long-arrow-right"></i></a>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="space-top" id="solution-home3">
        <div class="container" data-pos-space="#about-sec" data-sec-space="margin-bottom" data-margin-bottom="100px">
            <div class="title-area text-center">
                <div class="shadow-title">解决方案</div><span class="sub-title6 justify-content-center"><span
                        class="shape left"><span class="dots"></span></span> 解决方案三 <span class="shape right"><span
                            class="dots"></span></span></span>
                <h2 class="sec-title">智慧工地一体解决方案</h2>
            </div>
            <div class="row slider-shadow th-carousel" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                data-xs-slide-show="1" data-arrows="true">
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_1.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution3.html">全方位视频监控</a>
                            </h3>
                            <p class="service-box2_text">
                                全天候、全覆盖的视频监控系统，支持固定摄像头、移动布控球和无人机巡视。<br><br>
                            </p>
                        </div><a href="solution3.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_2.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution3.html">风险智能识别</a>
                            </h3>
                            <p class="service-box2_text">
                                AI技术实时识别并预警施工现场的环境和行为风险，确保安全隐患及时排除。<br><br>
                            </p>
                        </div><a href="solution3.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_3.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution3.html">智能化人员管理</a>
                            </h3>
                            <p class="service-box2_text">
                                通过实名制与资质管理、AI智能考勤、智能门禁等手段，实现精准的人员管理。<br><br>
                            </p>
                        </div><a href="solution3.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div class="service-box2" data-bg-src="assets/img/update1/bg/bg_pattern_14.png">
                        <div class="service-box2_content">
                            <div class="service-box2_icon"><img src="assets/img/update1/icon/service_3_4.svg"
                                    alt="Icon">
                            </div>
                            <h3 class="service-box2_title"><a href="solution3.html">高风险作业管控
                                </a>
                            </h3>
                            <p class="service-box2_text">
                                智能追踪和管理高风险作业情况，确保安全措施到位和重点管理人员监督到位。

                            </p>
                        </div><a href="solution3.html" data-bg-src="assets/img/update1/bg/service_btn_bg.jpg"
                            class="service-box2_btn">查看详情<i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>

            </div>
        </div>

    </section>

    <section class="blog-area-5 overflow-hidden space" id="solution-home4">
        <div class="container">

            <div class="title-area text-center">
                <div class="shadow-title">解决方案</div><span class="sub-title6 justify-content-center"><span
                        class="shape left"><span class="dots"></span></span> 解决方案四 <span class="shape right"><span
                            class="dots"></span></span></span>
                <h2 class="sec-title">风机智慧定检管控方案</h2>
            </div>

            <div class="row ">
                <div class="col-md-6 col-xl-3">
                    <div class="th-blog blog-single style5">
                        <div class="blog-img"><a href="solution4.html"><img src="assets/img/blog/blog-5-1.jpg"
                                    alt="Blog Image"></a>

                        </div>
                        <div class="blog-content">
                            <h4 class="box-title">全生命周期管理</h4>
                            <div class="blog-meta">实现风机定检从安装、运行到维护的全过程标准化、过程化、实时化、痕迹化管理。</div>
                            <a href="solution4.html" class="link-btn style2">了解更多<i
                                    class="fas fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="th-blog blog-single style5">
                        <div class="blog-img"><a href="solution4.html"><img src="assets/img/blog/blog-5-2.jpg"
                                    alt="Blog Image"></a>

                        </div>
                        <div class="blog-content">
                            <h4 class="box-title">AI大模型智能识别预警</h4>
                            <div class="blog-meta">利用AI大模型对风机缺陷进行智能识别与实时预警，提前发现潜在问题，确保风机高效运行。</div>
                            <a href="solution4.html" class="link-btn style2">了解更多<i
                                    class="fas fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="th-blog blog-single style5">
                        <div class="blog-img"><a href="solution4.html"><img src="assets/img/blog/blog-5-3.jpg"
                                    alt="Blog Image"></a>

                        </div>
                        <div class="blog-content">
                            <h4 class="box-title">大数据分析与预测</h4>
                            <div class="blog-meta">利用大数据平台和机器学习算法，对风机运行数据进行深度分析和建模，预测故障和性能退化。</div>
                            <a href="solution4.html" class="link-btn style2">了解更多<i
                                    class="fas fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3">
                    <div class="th-blog blog-single style5">
                        <div class="blog-img"><a href="solution4.html"><img src="assets/img/blog/blog-5-4.jpg"
                                    alt="Blog Image"></a>

                        </div>
                        <div class="blog-content">
                            <h4 class="box-title">智能定检无人机</h4>
                            <div class="blog-meta">采用无人机进行高效定检，配备高清摄像头和红外热成像仪，提高定检工作的效率和精度。 </div>
                            <a href="solution4.html" class="link-btn style2">了解更多<i
                                    class="fas fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="space bg-smoke overflow-hidden" id="solution-home5">
        <div class="shape-mockup service2-bg-shape1 jump" data-top="0" data-left="0"><img
                src="assets/img/service/service-bg-shape2-1.png" alt="img"></div>
        <div class="container">
            <div class="title-area text-center"><span class="sub-title2"><img class="me-1"
                        src="assets/img/icon/subtitle-img2-1.svg" alt="img">解决方案五</span>
                <h2 class="sec-title">双重预控管理解决方案</h2>
            </div>
            <div class="row gy-4 ">

                <div class="col-md-6 col-lg-3">
                    <div class="service-card style2">
                        <div class="service-card-bg-img" data-bg-src="assets/img/service/service-2-1.png"></div>
                        <div class="service-content">
                            <div class="service-card-icon"><img src="assets/img/icon/service-icon-2-2.svg" alt="icon">
                            </div>
                            <h3 class="box-title"><a href="solution7.html">风险动态识别与评估</a></h3>

                        </div>
                        <p class="service-card-text">利用人工智能和大数据分析技术，实动态识别和评估风险，即时预警，动态调整，确保安全生产。</p><a
                            class="th-btn style10" href="solution7.html">了解更多<i class="fas fa-arrow-right ms-1"></i></a>
                        <div class="service-card-shape jump-reverse"><img
                                src="assets/img/service/service-card-bg-2-1.png" alt="img"></div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="service-card style2">
                        <div class="service-card-bg-img" data-bg-src="assets/img/service/service-2-1.png"></div>
                        <div class="service-content">
                            <div class="service-card-icon"><img src="assets/img/icon/service-icon-2-1.svg" alt="icon">
                            </div>
                            <h3 class="box-title"><a href="solution7.html">安全态势实时展示</a></h3>

                        </div>
                        <p class="service-card-text">风险五色图动态展示企业安全态势，直观掌控，实时调整，确保安全防控。</p><a class="th-btn style10"
                            href="solution7.html">了解更多 <i class="fas fa-arrow-right ms-1"></i></a>
                        <div class="service-card-shape jump-reverse"><img
                                src="assets/img/service/service-card-bg-2-1.png" alt="img"></div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="service-card style2">
                        <div class="service-card-bg-img" data-bg-src="assets/img/service/service-2-1.png"></div>
                        <div class="service-content">
                            <div class="service-card-icon"><img src="assets/img/icon/service-icon-2-4.svg" alt="icon">
                            </div>
                            <h3 class="box-title"><a href="solution7.html">预警报警管理</a></h3>

                        </div>
                        <p class="service-card-text">
                            未按期开展风险评估和隐患排查自动报警，推动双控体系健康运行，防患于未然。

                        </p><a class="th-btn style10" href="solution7.html">了解更多<i
                                class="fas fa-arrow-right ms-1"></i></a>
                        <div class="service-card-shape jump-reverse"><img
                                src="assets/img/service/service-card-bg-2-1.png" alt="img"></div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="service-card style2">
                        <div class="service-card-bg-img" data-bg-src="assets/img/service/service-2-1.png"></div>
                        <div class="service-content">
                            <div class="service-card-icon"><img src="assets/img/icon/service-icon-2-3.svg" alt="icon">
                            </div>
                            <h3 class="box-title"><a href="solution7.html">应急救援智能支撑</a></h3>

                        </div>
                        <p class="service-card-text">
                            数字化应急预案库和语音播报系统，快速响应，科学指挥，减少事故损失。
                        </p><a class="th-btn style10" href="solution7.html">了解更多<i
                                class="fas fa-arrow-right ms-1"></i></a>
                        <div class="service-card-shape jump-reverse"><img
                                src="assets/img/service/service-card-bg-2-1.png" alt="img"></div>
                    </div>
                </div>

            </div>

        </div>
    </section>
    <section class="space" id="homenews">
        <div class="container">
            <div class="title-area text-center"><span class="sub-title2"><img class="me-1"
                        src="assets/img/icon/subtitle-img2-1.svg" alt="img">News</span>
                <h2 class="sec-title">新闻资讯</h2>

            </div>
            <div class="blog-grid-wrap">
                <div class="blog-grid" v-for="item in newsList" :key="item.id">
                    <div class="blog-img">
                        <!-- <img :src="`assets/img/blog/${item.cardImage}`"> -->
                        <img :src="`${item.cardImage}`">
                        <div class="blog-date"><span>{{ item.day }}</span> {{ item.month }}
                        </div>
                    </div>
                    <div class="blog-content">
                        <!-- <div class="blog-meta"><a class="author" href="blog.html"><i
                                    class="fa-solid fa-user"></i>网站管理员</a>
                            <a href="blog.html"><i class="fa-solid fa-tags"></i>公司新闻</a>
                        </div> -->
                        <h3 class="blog-title">{{ item.title }}</h3>
                        <p class="blog-text blog-textlarge">{{ item.content }}</p>
                        <a href="#" @click.prevent="goToNewsView(item.id)" class="link-btn">浏览详情<i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <div id="Brands">
        <div class="container">
            <div class="bg-title z-index-common" data-pos-for=".testi-area-1" data-sec-pos="top-half">
                <div class="client-area-1">
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <div class="title-area mb-lg-0 text-lg-start text-center">
                                <h2 class="sec-title text-white">重点客户名单</h2>
                                <p class="text-light">安可控累计已为3个行业，200余家客户提供服务。</p>

                            </div>
                        </div>
                        <div class="col-lg-7">


                            <div class="row">
                                <div class="col-sm-4 col-lg-3"><a href="#" class="client-thumb"><img
                                            src="assets/img/client/cilent_1_2.png" alt="img"></a></div>
                                <div class="col-sm-4 col-lg-3"><a href="" class="client-thumb"><img
                                            src="assets/img/client/cilent_1_1.png" alt="img"></a></div>
                                <div class="col-sm-4 col-lg-3"><a href="" class="client-thumb"><img
                                            src="assets/img/client/cilent_1_3.png" alt="img"></a></div>
                                <div class="col-sm-4 col-lg-3"><a href="" class="client-thumb"><img
                                            src="assets/img/client/cilent_1_4.png" alt="img"></a></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer-wrapper footer-layout2" data-bg-src="assets/img/bg/footer2-bg.png">
        <div class="widget-area space-top">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-6 col-xxl-5 col-xl-4">
                        <div class="widget footer-widget">
                            <div class="th-widget-about style2">
                                <div class="about-logo"><a href="index"><img src="assets/img/logo2.png"
                                            alt="img"></a>
                                </div>
                                <p class="about-text">
                                    安可控是一家致力于推动客户实现高质量发展和高水平安全的智能安全管控专家。安可控始终以先进的人工智能、大数据和物联网技术为核心动力，致力于以优质的产品和服务推动企业安全管理升级、智能化改造、经济增长和社会进步。依托于人工智能和数字化技术，安可控专注于为企业提供简单易用、实用管用的智能化产品，帮助客户完成数字化转型，发展新质生产力，实现高质量发展和高水平安全。
                                </p>
                                <div class="footer-info-grid">
                                    <div class="footer-info-wrap">
                                        <h6 class="footer-info-title">咨询热线</h6>
                                        <div class="footer-info"><i class="fas fa-phone"></i> <a href="tel:4007059928"
                                                class="info-box_link">4007059928</a></div>
                                    </div>
                                    <div class="footer-info-wrap">
                                        <h6 class="footer-info-title">联系电话</h6>
                                        <div class="footer-info"><i class="fas fa-phone"></i> <a href="tel:19918869363"
                                                class="info-box_link">19918869363</a></div>
                                    </div>
                                </div>
                                <div class="footer-info-grid">
                                    <div class="footer-info-wrap">
                                        <h6 class="footer-info-title">微信公众号</h6>
                                        <div><img src="assets/img/code.jpg"></div>
                                    </div>
                                    <div class="footer-info-wrap">
                                        <h6 class="footer-info-title">微博官方号</h6>
                                        <div><img src="assets/img/weibo.jpg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-auto">
                        <div class="widget widget_nav_menu footer-widget">
                            <h3 class="widget_title">解决方案</h3>
                            <div class="menu-all-pages-container">
                                <ul class="menu">
                                    <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                                    <li><a href="solution2.html">智慧安防高效解决方案</a></li>
                                    <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                                    <li><a href="solution3.html">智慧工地一体解决方案</a></li>
                                    <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                                    <li><a href="solution5.html">智能两票综合解决方案</a></li>
                                    <li><a href="solution6.html">智慧外包管理解决方案</a></li>
                                    <li><a href="solution7.html">双重预控智能管理方案</a></li>
                                    <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                                    <li><a href="solution9.html">智能锁控集成解决方案</a></li>
                                    <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                                    <li><a href="solution11.html">设备缺陷智能分析方案</a></li>
                                    <li><a href="solution12.html">在线考试管理解决方案</a></li>
                                    <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-auto">
                        <div class="widget widget_nav_menu footer-widget">
                            <h3 class="widget_title">基础服务</h3>
                            <div class="menu-all-pages-container">
                                <ul class="menu">
                                    <li><a href="product1.html">生产任务管理</a></li>
                                    <li><a href="product2.html">设备管理</a></li>
                                    <li><a href="product3.html">运行管理</a></li>
                                    <li><a href="product4.html">安全管理</a></li>
                                    <li><a href="product5.html">班组管理</a></li>
                                    <li><a href="product6.html">物资管理</a></li>
                                    <li><a href="product7.html">问题管理</a></li>
                                    <li><a href="product8.html">在线考试管理</a></li>
                                    <li><a href="product9.html">职业健康管理</a></li>
                                    <li><a href="product10.html">标准制度管理</a></li>
                                    <li><a href="product11.html">案例库管理</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 col-xl-auto">
                        <div class="widget widget_nav_menu footer-widget">
                            <h3 class="widget_title">增值服务</h3>
                            <div class="menu-all-pages-container">
                                <ul class="menu">
                                    <li><a href="product12.html">智能两票管理</a></li>
                                    <li><a href="product13.html">智慧外包管理</a></li>
                                    <li><a href="product14.html">智能点检管理</a></li>
                                    <li><a href="product15.html">全员绩效管理</a></li>
                                    <li><a href="product16.html">风险评估管理</a></li>
                                    <li><a href="product17.html">安全生产责任制</a> </li>
                                </ul>
                            </div>
                        </div>
                        <div class="widget widget_nav_menu footer-widget">
                            <h3 class="widget_title">特色模块</h3>
                            <div class="menu-all-pages-container">
                                <ul class="menu">
                                    <li><a href="product18.html">AI视频分析及预警</a></li>
                                    <li><a href="product19.html">实时定位和监控</a></li>
                                    <li><a href="product20.html">智能门禁管理</a></li>
                                    <li><a href="product21.html">智能锁控管理</a></li>
                                    <li><a href="product22.html">智能穿戴设备</a></li>
                                    <li><a href="product23.html">数据采集管理</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-wrap">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-6">
                        <p class="copyright-text">Copyright © 2024 <a href="index">湖南安可控科技有限公司</a> All Rights
                            Reserved.
                        </p>
                    </div>
                    <div class="col-md-6 text-end d-none d-md-block">
                        <div class="footer-links">
                            <ul>
                                <li><a href="about.html">京ICP备19044307号</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    <div class="scroll-top"><svg class="progress-circle svg-content" width="100%" height="100%" viewbox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;">
            </path>
        </svg></div>
</template>

<script setup>
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { onBeforeUnmount, ref, shallowRef, onMounted, onUnmounted, reactive } from 'vue';
import http from '@/shared/http.service'; 
import { Uris, DefaultConfig } from '@/shared/const';
import { Subject, from, EMPTY } from 'rxjs';
import { catchError, switchMap, throttleTime } from 'rxjs/operators';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';

onMounted(() => {
  document.title = '安可控 - 智能安全专家 | 安全生产智能管控平台 | 安全生产管理系统 | 智慧电厂解决方案';

  const metaKeywords = document.createElement('meta');
  metaKeywords.name = 'keywords';
  metaKeywords.content = '安可控,智能安全专家，安全生产智能管控平台,安全生产，安全管理，生产管理系统，安全生产管理，智能工厂，电厂生产管理系统，智慧电厂';
  document.head.appendChild(metaKeywords);

  const metaDescription = document.createElement('meta');
  metaDescription.name = 'description';
  metaDescription.content = '安可控，智能安全专家，专注于安全生产智能管控平台建设，提供覆盖安全生产管理、智能工厂、电厂生产管理系统的全方位解决方案，助力企业实现智慧电厂的高效、安全运营。';
  document.head.appendChild(metaDescription);
  getNewsList(1);
});
const isActive = ref(false);
const toggleActive = () => {
  isActive.value = !isActive.value;
}

const newsList = ref([]);
const router = useRouter();

const pageList = reactive({
  total: 0,
  current: 1,
  pageSize: 3,//首页只获取三条新闻
  totalPageCount: 1
});

 

///获取新闻列表
const detail$ = new Subject();
const detailSubscription = detail$
  .pipe(
    switchMap((requestModel) => {  
      return http.get(Uris.List, requestModel);
    }),
    catchError((err, caught) => {
      return caught;
    })
  )
  .subscribe(result => {

    if (!result.hasNextPage) {
      pageList.current = result.totalPageCount > 0 ? result.totalPageCount : 1;
    } else {
      pageList.current = result.pageIndex;
    }
    pageList.totalPageCount = result.totalPageCount;
    pageList.pageSize = result.pageSize;
    pageList.total = result.totalCount;

    result.list.forEach(element => {
      const model = {
        id: element.id,
        title: element.title,
        month: dayjs(element.stamp).format('MM'),
        day: dayjs(element.stamp).format('DD'),
        intro: element.intro,
        cardImage: `${window.serverConfig.baseUrl}/${element.cardImage}`,
      };
      newsList.value.push(model);
    });
  });

const getNewsList = (number) => {
  newsList.value = [];
  const requestModel = {
    pageIndex: number,
    pageSize: pageList.pageSize,
    orderBy: "stamp",
    direction: "Desc",
  };
  detail$.next(requestModel);
};

const goToNewsView = (id) => {
  // router.push({ name: 'newsview', params: { nid: id } });
  router.push({ path: `/newsview/${id}` });
};


onUnmounted(() => {
  detailSubscription.unsubscribe();
});

// 导入并在模板中使用组件
</script>
<style>
.footer-layout2 {
    padding-top: 120px;
}

.th-hero-slide img:last-child {
    display: none;
}

#solution-home .service-box2_text {
    height: 120px;
    overflow: auto;
    text-align: justify;
}

.service-box2_title,
.service-card2_title {
    white-space: nowrap;
}

#solution-home2 .service-card2_text {
    text-align: justify;
    height: 150px;
    overflow: auto;
}

.service-card2_btn {
    height: 150px;
    line-height: 150px;
}

#solution-home3 .service-box2_text {
    text-align: justify;
    height: 100px;
}

#solution-home4 .box-title {
    white-space: nowrap;
}

#solution-home4 .blog-meta {
    height: 120px;
    text-align: justify;
}

#solution-home5 .service-card-text {
    height: 110px;
    overflow: hidden;
}

.service-card.style2 {
    height: 340px;
    overflow: hidden;
    padding: 20px;
}

.box-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#hero h1 {
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
}

.ant-carousel .slick-slide {
    text-align: center;
    height: 100vh;
    line-height: 100vh;
    background: #364d79;
    overflow: hidden;
    position: relative;
}

.ant-carousel .slick-arrow.custom-slick-arrow {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #fff;
    background-color: rgba(31, 45, 61, 1);
    opacity: 0.3;
    z-index: 1;
    transition: all .3s;
}

.ant-carousel .custom-slick-arrow:before {
    display: none;
}

.ant-carousel .custom-slick-arrow:hover {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #fff !important;
    background-color: rgba(31, 45, 61, .5) !important;
    opacity: 1;
    z-index: 9;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.slick-dots button:before {
    border: 0 !important
}

.slick-slide>div>div {
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
}

.slick-slide>div>div::before {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0
}

.slick-slide>div>div h3 {
    position: absolute;
    left: 0;
    top: 50%;
    height: 80px;
    margin-top: -40px;
    text-align: center;
    width: 100%;
    font-size: 60px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5)
}

.slick-slide>div>div h3>p {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase
}

.slick-slide>div>div h3>div {
    padding-top: 10px;
}

.carousel1 {
    background-image: url(/src/assets/img/hero/hero_bg_4_5.jpg);
}

.carousel2 {
    background-image: url(/src/assets/img/hero/hero_bg_4_2.jpg);
}

.carousel3 {
    background-image: url(/src/assets/img/hero/hero_bg_4_1.jpg);
}

.carousel4 {
    background-image: url(/src/assets/img/hero/hero_bg_4_4.jpg);
}

.header-layout3 {
    width: 100% !important;
    position: fixed !important;
}

.header-layout3 .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100% !important;
}

#product-home {
    background-image: url(/src/assets/img/update1/bg/service_bg_3.jpg);
}

#solution-home2 {
    background-image: url(/src/assets/img/update1/bg/service_bg_1.png);
}

.th-carousel {
    margin-bottom: -70px;
}

@media(max-width: 575px) {
    .header-layout3 .header-top {
        background-color: #000 !important;
    }

    .slick-slide>div>div h3 {

        font-size: 36px;

    }

    .slick-slide>div>div h3>p {

        font-size: 14px;

    }
}
</style>