import http from '@/shared/http.service';
import { Uris, DefaultConfig } from '@/shared/const';
import { Subject, from, EMPTY } from 'rxjs';
import { ref, onUnmounted, reactive, computed, onMounted } from 'vue';
import { Form, notification } from "ant-design-vue";
import { catchError, switchMap, throttleTime } from 'rxjs/operators';
import dayjs from 'dayjs';
import { useIdentityStore } from '@/stores/useIdentityStore';
import { useRouter } from 'vue-router';


const useForm = Form.useForm;
export default function useHome() {
    const store = useIdentityStore();
    const router = useRouter();
    const currentUser = computed(() => store.currentUser);
    // console.log(currentUser.value)
    // if (!currentUser.value) {
    //     router.push('signin');
    // }


    const isAdd = ref(false);
    const form = reactive({
        id: "",
        title: "",
        content: "",
        stamp: null,
        publisher: "",
        intro: "", 
        cardImage: "",
        pageTopics:'',
        cardImageFiles: []
    });

    let rules = reactive({
        title: [{ type: 'string', required: true, message: '请输入新闻标题', trigger: 'change' }],
    });

    let { resetFields, validate, validateInfos } = useForm(form, rules);

    const defaultOrderBy = 'stamp';
    const defaultDirection = 'Desc';
    const params = reactive({
        pageIndex: 1,
        pageSize: DefaultConfig.PageSize,
        orderBy: defaultOrderBy,
        direction: defaultDirection,
        title: "",
        startTime: null,
        endTime: null,
    });

    const pagination = reactive({
        current: 1,
        pageSize: DefaultConfig.PageSize,
        total: 0
    });

    //分页
    const handleTableChange = (pager) => {
        // console.log(pager)
        const serchEntity = {
            title: params.title ? params.title : "",
            pageIndex: pager.current,
            pageSize: pager.pageSize,
            orderBy: params.orderBy ? params.orderBy : '',
            direction: params.direction ? params.direction : '',
        }
        getNewsList(serchEntity);
    }

    const rid = ref("");
    ///获取ID
    const GetId$ = new Subject();
    const GetIdSubscription = GetId$.pipe(
        // throttleTime(500),
        switchMap(() => http.get(Uris.GetId))
    ).subscribe(result => {
        rid.value = result;
        form.id = result
    });

    const getKeyId = () => {
        GetId$.next();
    }

    const newsList = ref([])
    //获取新闻列表
    const getNews$ = new Subject();
    const getNewsSubscription = getNews$.pipe(
        switchMap((request) => http.get(Uris.List, request))
    ).subscribe(result => {

        newsList.value = result.list;
        if (!result.hasNextPage) {
            pagination.current = result.totalPageCount > 0 ? result.totalPageCount : 1;
        } else {
            pagination.current = result.pageIndex;
        }
        pagination.pageSize = result.pageSize;
        pagination.total = result.totalCount;

    });

    const getNewsList = (requestModel) => {
        // console.log(requestModel);
        if (requestModel) {
            params.title = requestModel.title;
            params.startTime = requestModel.startTime;
            params.endTime = requestModel.endTime;
            params.pageIndex = requestModel.pageIndex;
            params.pageSize = requestModel.pageSize;
        }
        // console.log(params);
        getNews$.next(params);
    };

    //修改列表中图片值
    // const edit$ = new Subject();
    // const 

    //编辑
    const equEdit$ = new Subject();
    const equEdited$ = new Subject();
    const equEditSubscription = equEdit$.pipe(
        switchMap(requestModel => {
            return http.get(Uris.Get, { articleId: requestModel.id })
        })).subscribe((v) => {
            // console.log(v);
            // form.id = v.id;
            // form.title = v.title;
            // form.content = v.content;
            // form.stamp = dayjs(v.stamp);
            // form.intro = v.intro;
            // form.publisher = v.publisher;
            // form.cardImageFiles = v.cardImageFiles ? v.cardImageFiles : [];
            // console.log(form) 
            equEdited$.next(v);
        })

    const editModal = requestModel => {
        resetFields();
        equEdit$.next(requestModel);
    };

    const save$ = new Subject();
    const saveSubscription = save$.pipe(
        switchMap(entity => {
            entity.cardImageFiles = [];
            return http.post(Uris.SaveEquipment, entity)
        })
    ).subscribe(result => {
        if (result) {

            // isVisible.value = false;
        }
    });

    //保存
    const saved$ = new Subject();
    const handleSave = (type) => {
        from(validate()).pipe(
            switchMap(validateResult => {
                form.cardImageFiles = [];
                if (!isAdd.value) {
                    return http.put(Uris.Put, form);
                }
                else
                    return http.post(Uris.Post, form);
            }), catchError((err, caught) => {
                console.log(err)
                return EMPTY;
            })
        ).subscribe(result => {
            notification.success({
                message: '数据已保存',
                description: '',
                duration: 2
            });
            //恢复字段验证规则
            saved$.next(type); 
        });
    }

    const deleteNews$ = new Subject();
    const deleteNewsSubScription = deleteNews$.pipe(
        switchMap(requestModel => {
            return http.delete(Uris.Delete, { articleId: requestModel.id })
        })).subscribe(result => {
            if (result) {
                getNewsList(params);
            }
        });

    const removeNews = (requestModel) => {
        deleteNews$.next(requestModel);
    };

    onUnmounted(() => {
        getNewsSubscription.unsubscribe();
        equEditSubscription.unsubscribe();
        saveSubscription.unsubscribe();
        GetIdSubscription.unsubscribe();
    });

    return {
        getNewsList,
        newsList,
        pagination,
        handleTableChange,
        resetFields,
        validate,
        validateInfos,
        editModal,
        handleSave,
        form,
        saved$,
        getKeyId,
        rid,
        isAdd,
        removeNews,
        currentUser,
        equEdited$,
    };

}