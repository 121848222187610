<template>
    <div class="loginpage">
        <vue-particles color="#fff" :particle-opacity="0.4" :particles-number="140" shape-type="circle"
            :particle-size="6" lines-color="#fff" :lines-width="1" :line-linked="true" :line-opacity="0.3"
            :lines-distance="160" :move-speed="4" :hover-effect="false" hover-mode="repulse" :click-effect="true"
            click-mode="repulse" class="lizibg" id="signlogin" />

        <a-form class="loginform" :model="form" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
            autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
            <div class="logintitle">
                <img src="../../images/logo.png" alt=""> <strong>网站后台登录</strong>
            </div>

            <a-form-item label="用户名称" name="account" v-bind="validateInfos.account">
                <a-input v-model:value="form.account" />
            </a-form-item>

            <a-form-item label="用户密码" name="password" v-bind="validateInfos.password">
                <a-input-password v-model:value="form.password" />
            </a-form-item>
            <!-- <a-form-item label="验&nbsp;&nbsp;证&nbsp;&nbsp;码" name="key"
                :rules="[{ required: true, message: '请输入正确的验证码' }]">
                <a-input-group compact>
                    <a-input v-model:value="value19" style="width: calc(100% - 62px)" />
                    <a-button type="default" style="padding: 0;"><img src="../../images/ma.jpg" style="width: 60px;"
                            alt=""></a-button>
                </a-input-group>
            </a-form-item> -->

            <!-- <a-form-item name="remember" :wrapper-col="{ offset: 0, span: 24 }">
                <a-checkbox v-model:checked="formState.remember" suffix="RMB">记住我</a-checkbox>
                <a href="#" style="float: right;">忘记密码</a>
            </a-form-item> -->

            <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
                <a-button type="primary" size="middle" html-type="submit" @click="signIn"
                    style="width: 100%;">确认登录</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
import useSignIn from '@/composables/useSignIn';
import {
    defineComponent,
    reactive
} from 'vue';
import {
    useRouter
} from 'vue-router';


const { spinning, form, validateInfos, signIn, getCookie, } = useSignIn();
const router = useRouter();
// const signIn = (e) => {
//     router.replace({
//         name: 'home'
//     });
// };
// const formState = reactive({
//     username: '',
//     password: '',
//     remember: true,
// });
const onFinish = values => {
    console.log('Success:', values);
};
const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};


</script>

<style lang="less">
.lizibg {
    height: 100% !important;

}

.loginpage {
    background-image: url(../../images/loginbg.jpg);
    background-position: center top;
    height: 100%;
}

form.loginform {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(5px);
    width: 320px;
    height: 410px;
    margin-left: -160px;
    margin-top: -200px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .4);
}

div.logintitle {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
}

div.logintitle img {
    height: 28px;
}

form.loginform div.ant-form-item-label {
    text-align: left;
}

div.logintitle strong {
    display: block;
    white-space: nowrap;
    font-size: 18px;
}
</style>
