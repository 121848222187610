import { ref, reactive, onUnmounted } from 'vue';
import { useIdentityStore } from '@/stores/useIdentityStore';
import { useRouter } from 'vue-router';
import { from, Subject } from 'rxjs';
import { catchError, switchMap, throttleTime, } from 'rxjs/operators';
import { Form, notification } from 'ant-design-vue';
import { Uris, DefaultConfig } from '@/shared/const';
import http from '@/shared/http.service';
const useForm = Form.useForm;

export default function useSignIn() {
    const store = useIdentityStore();
    const router = useRouter();
    const spinning = ref(false);
    const form = reactive({
        userId: null,
        // account: import.meta.env.DEV ? import.meta.env.VITE_USER : null,
        // password: import.meta.env.DEV ? import.meta.env.VITE_PASSWORD : null,
        account: process.env.DEV ? import.meta.env.VITE_USER : null,
        password: process.env.DEV ? import.meta.env.VITE_PASSWORD : null,
    });
    const rules = reactive({
        account: [{ type: 'string', required: true, message: '请输入用户账号', trigger: 'change', }],
        password: [{ type: 'string', required: true, message: '请输入用户密码', trigger: 'change', }],

    });
    const { clearValidate, validate, validateInfos } = useForm(form, rules);

    // signin
    const signIn$ = new Subject();
    const signInSubscription = signIn$
        .pipe(
            throttleTime(1000),
            switchMap(() => {
                spinning.value = true;
                return from(validate(['account', 'password']));
            }),
            switchMap(() => {
                const model = {
                    account: form.account,
                    password: form.password,
                };
                return http.post(Uris.RequestPasswordToken, model);
            }),
            switchMap(result => {
                console.log(result.expiresAt);
                store.setExpiresAt(result.expiresAt);
                store.setAccessToken(result.accessToken);
                store.setRefreshToken(result.refreshToken);
                return http.post(Uris.CurrentUser);
            }),
            catchError((err, caught) => {
                spinning.value = false;
                if (err.response && err.response.status === 400 && err.response.data === "invalid_grant") {
                    notification.error({
                        key: 'httperror',
                        message: "错误",
                        description: "用户名或者密码错误！",
                        duration: 2,
                    });
                }
                return caught;
            })
        ).subscribe(result => {
            // console.log(result);
            spinning.value = false;
            store.setCurrentUser(result);
            store.setAccount(form.account);
            router.replace({ name: 'index' });
        });
    const signIn = () => {
        signIn$.next();
    };

    onUnmounted(() => {
        signInSubscription.unsubscribe();
    });

    return {
        spinning,
        form,
        validateInfos,
        signIn
    };
}