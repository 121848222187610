import { defineStore, acceptHMRUpdate } from "pinia";
import { ref, computed } from "vue";

export const useIdentityStore = defineStore("identity", () => {
  const account = ref(null);
  const expiresAt = ref(null);
  const accessToken = ref(null);
  const refreshToken = ref(null);
  const currentUser = ref(null);

  const setAccount = (value) => {
    account.value = value;
    if (value) {
      localStorage.setItem("account", value);
    } else {
      localStorage.removeItem("account");
    }
  };

  const setExpiresAt = (value) => {
    expiresAt.value = value;
    if (value) {
      localStorage.setItem("expiresAt", value);
      console.log("expiresAt存储成功:"+value);
    } else {
      localStorage.removeItem("expiresAt");
    }
  };

  const setAccessToken = (value) => {
    accessToken.value = value;
    if (value) {
      localStorage.setItem("accessToken", value);
      console.log("accessToken存储成功:"+value);
    } else {
      localStorage.removeItem("accessToken");
    }
  };

  const setRefreshToken = (value) => {
    refreshToken.value = value;
    if (value) {
      localStorage.setItem("refreshToken", value);
    } else {
      localStorage.removeItem("refreshToken");
    }
  };

  const setCurrentUser = (value) => {

    var user = localStorage.getItem("currentUser");
    // console.log(user);
    if (!user) {
      currentUser.value = value;
      if (value) {
        value = JSON.stringify(value);
        // console.log(value);
        localStorage.setItem("currentUser", value);
      } else {
        localStorage.removeItem("currentUser");
      }
    }
    else {
      currentUser.value = user
    }


  };

  const signOut = () => {
    setAccount(null);
    setExpiresAt(null);
    setAccessToken(null);
    setRefreshToken(null);
    setCurrentUser(null);
  };

  const isSignIn = computed(
    () =>
      accessToken.value &&
      expiresAt.value 
      //expiresAt.value >= new Date().getTime()
  );

  return {
    account,
    expiresAt,
    accessToken,
    refreshToken,
    currentUser,
    isSignIn,
    setAccount,
    setExpiresAt,
    setAccessToken,
    setRefreshToken,
    setCurrentUser,
    signOut,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIdentityStore, import.meta.hot));
}
