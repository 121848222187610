import { createRouter, createWebHistory } from 'vue-router';
import { useIdentityStore } from '@/stores/useIdentityStore';
import Home from '@/views/home/home.vue';
import SignIn from '@/views/identity/sign.in.vue';
import news from '@/views/page/news.vue';
import newsview from '@/views/page/newsview.vue';
import DynamicHtmlPage from '../components/DynamicHtmlPage.vue';
import index from '@/views/page/default.vue'; 

const routes = [
  {
    path: '/',
    redirect: { name: 'index' }
  },
  {
    path: '/index',
    name: 'index',
    meta: { allowAnonymous: true }, 
    component: index,
  }, 
  {
    path: '/home',
    name: 'Home',
    meta: { allowAnonymous: false },
    component: Home,
  },
  {
    path: '/news',
    name: 'news',
    meta: { allowAnonymous: true },
    component: news,

  },
  {
    //path: '/newsview/:nid',
    path: '/newsview',
    name: 'newsview',
    meta: { allowAnonymous: true },
    component: newsview,

  }, 
  {
    path: '/signin',
    name: 'Signin',
    meta: { allowAnonymous: true },
    component: SignIn,
  }, 
  
  /*  
  {
    path: '/admin',
    name: 'Admin',
    meta: { allowAnonymous: false },
    component: () => import('@/views/admin/home.vue'),
    children: [   
     
      {
        path: 'params',
        name: 'SettingParams',
        meta: { allowAnonymous: false },
        component: () => import('@/views/admin/params.vue'),
      }, 
    ],
  },  */
];

// const router = createRouter({
//   history: createWebHistory(import.meta.env.BASE_URL),
//   routes,
// });
const router = new createRouter({
  history: createWebHistory(), // 可以根据需求选择不同的路由模式，这里使用历史模式
  routes
});
// router.beforeEach((to) => {
//   window.scrollTo(0, 0);
//   const store = useIdentityStore();
//   if (to.meta.allowAnonymous) {
//     return true;
//   }
//   if (!localStorage.getItem('accessToken')) {
//     return { name: 'Signin', replace: true };
//   }
 
//   return true;
// });

router.beforeEach((to) => {
  if (to.path === '/') {
    // 如果是根路径，重定向到默认页
    //next('/index');
    return {name:'index',replace:true};
  }
  if (to.meta.allowAnonymous) {
    return true;
  }
  else if (!localStorage.getItem('accessToken')) {
    return { name: 'Signin', replace: true };
  }
  
});


export default router;
