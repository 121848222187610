import { useIdentityStore } from '@/stores/useIdentityStore';
import axios from 'axios';
import qs from 'qs';
import { from, of, throwError } from 'rxjs';
import { catchError, timeout, switchMap } from 'rxjs/operators';
import { notification } from 'ant-design-vue';
import { ErrorMessage } from '@/shared/const';

/** 超时时间 */
const DefautTimeout = 1000 * 60 * 30;
/** 错误显示时间 */
const ErrorNotifyDuration = 2;


export default {
    get(url, requestModel) {
        const store = useIdentityStore();
        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.get(
                url,
                {
                    headers,
                    params: requestModel,
                    paramsSerializer: params => qs.stringify(params, { indices: false })
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => of(result.data)),
            catchError(this.handleError)
        );
    },
    post(url, data) {
        const store = useIdentityStore();
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.post(
                url,
                data,
                {
                    headers
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => of(result.data)),
            catchError(this.handleError)
        );
    },
    put(url, data) {
        const store = useIdentityStore();
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.put(
                url,
                data,
                {
                    headers
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => of(result.data)),
            catchError(this.handleError)
        );
    },
    delete(url, requestModel) {
        const store = useIdentityStore();
        const headers = {
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.delete(
                url,
                {
                    headers,
                    params: requestModel,
                    paramsSerializer: params => qs.stringify(params, { indices: false })
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => of(result.data)),
            catchError(this.handleError)
        );
    },
    upload(url, data) {
        const store = useIdentityStore();
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.post(
                url,
                data,
                {
                    headers,
                    //paramsSerializer: params => qs.stringify(params, { indices: false }),
                    responseType: 'blob'
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => {
                result.data.id = decodeURI(result.headers.id);
                result.data.filename = decodeURI(result.headers.filename);
                return of(result.data);
            }),
            catchError(this.handleError)
        );
    },
    uploadChunk(url, data) {
        const store = useIdentityStore();
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return axios.post(
            url,
            data,
            {
                headers,
                // paramsSerializer: params => qs.stringify(params, { indices: false }),
                responseType: 'blob'
            }
        );
    },
    download(url, requestModel) {
        const store = useIdentityStore();
        const headers = {
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.get(
                url,
                {
                    headers,
                    params: requestModel,
                    paramsSerializer: params => qs.stringify(params, { indices: false }),
                    responseType: 'blob'
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => {
                console.log(result);
                result.data.filename = decodeURI(result.headers.filename);
                return of(result.data);
            }),
            catchError(this.handleError)
        );
    },

    export(url, data) {
        const store = useIdentityStore();
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.accessToken
        };
        return from(
            axios.post(
                url,
                data,
                {
                    headers,
                    //paramsSerializer: params => qs.stringify(params, { indices: false }),
                    responseType: 'blob'
                }
            )
        ).pipe(
            timeout(DefautTimeout),
            switchMap(result => {
                //console.log(result);
                result.data.filename = decodeURI(result.headers.filename);
                return of(result.data);
            }),
            catchError(this.handleError)
        );
    },

    handleError(err, caught) {
        if (!err.response?.data?.hidemsg) {
            const details = ErrorMessage[err.response?.data] || ErrorMessage[err.response?.data?.errcode] || err.response?.data?.msg || err.response?.title || err.response?.data;
            notification.error({
                key: 'httperror',
                message: err.message,
                description: details,
                duration: ErrorNotifyDuration,
            });
        }
        return throwError(() => err);
    },
};