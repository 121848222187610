<template>
    <section class="page">
      <main>
        <div class="header">
          <div class="headerright">
            <a-button type="primary" shape="round" @click="showModal">
              <template #icon>
                <file-add-outlined />
              </template>
              新增
            </a-button>
            <a-modal v-model:visible="visible" title="新闻新增" @ok="handleOk" width="100%" centered wrap-class-name="full-modal">
                        <a-form :label-col="labelCol">
                        
    <a-row>
      <a-col :span="12"> <a-form-item label="发布时间" required="">
                                <a-date-picker show-time placeholder="Select Time"   v-model:value="form.stamp" />
            </a-form-item></a-col>
      <a-col :span="12"> <a-form-item label="发布人" required="">
                <a-input placeholder="系统管理员"  v-model:value="form.publisher" />
            </a-form-item></a-col>
    </a-row>  
    <a-form-item label="页面标题" ><a-input placeholder="title" v-model:value="form.title" /></a-form-item>
   <a-form-item label="页面描述" ><a-input placeholder="description" v-model:value="form.intro" /></a-form-item>
   <a-form-item label="页面关键字" ><a-input placeholder="PageTopics" v-model:value="form.pageTopics" /></a-form-item>
   <a-col :span="12">
                    <a-form-item label="新闻图片">
                      <file-upload ref="NewsFileUpload" :maxLength="1" :fileList="form.cardImageFiles"
                        :relationId="form.id" relationModule="NewsPhotoFiles" fileType="image" uploader="admin"
                        :isMultiple="false" @upload-complete="filesPhotosComplete" @del-file="NewsPhotosDel"
                        @add-file="newsPhotosAdd" />
                    </a-form-item>
    </a-col>

    <a-form-item label="新闻内容" required="">
               
                <div style="border: 1px solid #ccc">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="form.content"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
      />
    </div>
            </a-form-item>
</a-form>
                        <template #footer>
                            <a-button key="back" type="primary" @click="handleAdd">保存并新增</a-button>
                            <a-button key="submit" type="primary" @click="handleClose">保存并关闭</a-button>
                            <a-button key="cancel" type="primary" @click="handleCancel">取消</a-button>
                        </template>
                    </a-modal>
            
          </div>
  
          <strong>智慧工地网站新闻动态管理</strong>
        </div>
        <div class="pagemain">
          <div class="tblbox">
            <div class="searchbox">
              <a-form layout="inline">
                <a-form-item label="新闻标题">
                  <a-input placeholder="请输入" v-model:value="requestData.title" />
                </a-form-item>
                <a-form-item label="发布时间">
                  <a-range-picker v-model:value="requestData.stamp" />
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" @click="search">查询</a-button>
                </a-form-item>
              </a-form>
            </div>
            <a-table size="small" :dataSource="newsList" :columns="columns" :scroll="{ y: 'calc(100vh - 280px)' }"
              :pagination="pagination" @change="handleTableChange"
              :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'sn'">
                  {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
                </template>
                <template v-if="column.key === 'operation'">
                  <a-dropdown placement="bottomRight">
                    <a-space>
                      <!-- <a href="javascript:;">查看</a> -->
                      <a href="javascript:;" @click.prevent="editModel(record)">修改</a>
                      <a-popconfirm placement="left" title="确认删除?" ok-text="确认" cancel-text="取消"
                        @confirm="removeNews(record)">
                        <a title="删除" href="javascript:;" style="margin-left: 5px;">删除</a>
                      </a-popconfirm>
                    </a-space>
                  </a-dropdown>
                </template>
              </template></a-table>
          </div>
        </div>
      </main>
    </section>
  </template>
  
  <script setup>
  import '@wangeditor/editor/dist/css/style.css';
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
  import useHome from '@/composables/useHome';
  import fileUpload from '@/components/file.upload.vue';
  
  import { onBeforeUnmount, ref, shallowRef, onMounted, computed, reactive, defineComponent, getCurrentInstance, nextTick } from 'vue';
  import { FileAddOutlined, } from '@ant-design/icons-vue';
  import http from '@/shared/http.service';
  import { Uris, DefaultConfig } from '@/shared/const';
  
  
  import dayjs from 'dayjs';
  const instance = getCurrentInstance();
  
  
  
  const editorConfig = {                       // JS 语法
    MENU_CONF: {}
  
    // 其他属性...
  }
  
  const requestData = reactive({
    title: '',
    stamp: null,
    pageIndex: 1,
    pageSize: DefaultConfig.PageSize,
  })
  
  
  const { getNewsList, newsList, pagination, params, handleTableChange,
    resetFields,
    validate,
    validateInfos,
    handleSave,
    form,
    saved$,
    getKeyId,
    rid,
    isAdd,
    editModal,
    removeNews,
    equEdited$,
  } = useHome();
  
  
  
  
  
  let mode = ref('default')
  const type = ref('')
  
  const newstitle = ref('新增新闻');
  //ommounted
  onMounted(() => {
  
  
    getNewsList(requestData);
    saved$.subscribe(async (result) => { 
      visible.value = false;
      saved(result);
      // console.log(result)
      // if (result == 'close') {
      //   await saved();
      //   // console.log(instance.refs.NewsFileUpload)
  
      // }
      // if (result == 'add') {
      //   await saved();
  
      // }
    });
  
    equEdited$.subscribe((v) => {   
      if (v) {
        form.id = v.id;
        form.title = v.title;
        form.content = v.content;
        form.stamp = dayjs(v.stamp);
        form.pageTopics=v.pageTopics; 
        form.intro = v.intro;
        form.publisher = v.publisher;
        form.cardImageFiles = v.cardImageFiles ? v.cardImageFiles : [];
        console.log(form.cardImageFiles);
      }
    });
  
  
  
  });
  
  
  const saved = async (result) => { 
    if (instance.refs.NewsFileUpload.fileAttachments?.length > 0 || instance.refs.NewsFileUpload.removedAttachments?.length > 0) {
      //新闻图片上传
      await nextTick(() => {
        instance.refs.NewsFileUpload.uploadFile();
      }).finally(() => {
  
        type.value = result;
        getNewsList(requestData);
      });
    }
    else {
      // getNewsList(requestData);
      if (result == 'add') {
        showModal();
      }
      else {
        getNewsList(requestData);
      }
  
  
    }
  };
  //文件上传完成后的事件处理
  const filesPhotosComplete = (result) => {
    console.log(result)
    if (result) {
      if (result.files.length > 0) {
        const url = result.files[0].url;
        const requestModel = {
          cardImage: url,
          id: rid.value
        };
        http.put(Uris.Edit, requestModel).subscribe((retrunresult) => {
          getNewsList(requestData);
        });
      }
  
    }
    form.cardImageFiles = []
    if (type.value == 'add') {
      showModal();
    }
  };
  
  const handleAdd = () => {
    handleSave('add');
  };
  
  const handleClose = () => {
    handleSave('close');
  };
  
  //图片上传成功后
  const newsPhotosAdd = (result) => {
    // console.log(result)
    form.cardImageFiles = instance.refs.NewsFileUpload.fileAttachments;
  
  };
  
  // 删除图片
  const NewsPhotosDel = (file) => {
  
    form.cardImageFiles = instance.refs.NewsFileUpload.fileAttachments;
    let index = form.cardImageFiles.findIndex(item => item.uid === file.uid);
    if (index !== -1) {
      form.cardImageFiles.splice(index, 1);
    }
    // console.log(result)
    // const param = {
    //   id: result.uid
    // }
    // if (result.uid && !result.uid.includes('-'))
    //   http.delete(Uris.DeleteFile, param).subscribe((retrunresult) => {
    //     form.cardImageFiles = []
    //   })
  };
  
  const search = () => { 
    const searchModel = {
      title: "",
      startTime: null,
      endTime: null,
      pageIndex: 1,
      pageSize: DefaultConfig.PageSize
    };
    searchModel.title = requestData.title;
    if (requestData.stamp != null) {
      searchModel.startTime = dayjs(requestData.stamp[0]).format('YYYY-MM-DD');
      searchModel.endTime = dayjs(requestData.stamp[1]).format('YYYY-MM-DD');
    } else { 
        
      searchModel.startTime = null;
      searchModel.endTime = null;
    }
  
    getNewsList(searchModel);
  };
  
  
  const uploadFile = () => { 
    //文本编辑器图片上传
    editorConfig.MENU_CONF['uploadImage'] = {
  
      placeholder: '请输入新闻内容...',
      allowedFileTypes: ['image/*'],
      server: window.serverConfig.baseUrl + '/api/Article/UploadFile',
      // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
      meta: {
        relationModule: "NewsPhotos",
        relationId: rid.value,
      },
      timeout: 10 * 1000, // 10 秒
    };
  
  
    //文本编辑器视频上传
    editorConfig.MENU_CONF['uploadVideo'] = {
  
      placeholder: '请输入新闻内容...',
      allowedFileTypes: ['video/*'],
      server: window.serverConfig.baseUrl + '/api/Article/UploadFile',
      // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
      meta: {
        relationModule: "NewsVideos",
        relationId: rid.value,
      },
      timeout: 10 * 60 * 1000, // 10 分钟
  
  
    };
  }
  
  const handleCreated = (editor) => {
    editorRef.value = editor; // 记录 editor 实例，重要！
    // getKeyId();
  };
  const visible = ref(false);
  //新增弹出窗口
  const showModal = () => {
    resetFields();
    if (instance.refs.NewsFileUpload) {
      instance.refs.NewsFileUpload.fileAttachments = [];
    }
  
    visible.value = true;
    isAdd.value = true;
    getKeyId();//获取IdHelper生成的ID值，上传图片时需要
    uploadFile();
  };
  const reid = ref('');
  //修改弹出窗口
  const editModel = (v) => {
    editModal(v);
    isAdd.value = false;
    newstitle.value = '修改新闻';
    visible.value = true;
  
    rid.value = v.id;
    uploadFile();
  };
  //保存并关闭
  
  //关闭
  const handleCancel = (e) => {
    visible.value = false;
    getNewsList(requestData);
  };
  
  
  const editorRef = ref(null);
  const toolbarConfig = {};
  
  
  // 组件销毁时，也及时销毁编辑器
  onBeforeUnmount(() => {
    const editor = editorRef.value;
    if (editor == null) return;
    editor.destroy();
  });
  
  
  const labelCol = {
    style: {
      width: '90px',
    },
  };
  const columns = [
    {
      title: '序号',
      dataIndex: 'sn',
      key: 'sn',
      width: 50,
      align: 'center',
    },
    {
      title: '新闻标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '发布时间',
      dataIndex: 'stamp',
      key: 'stamp',
      width: 150,
    },
    {
      title: '发布人',
      dataIndex: 'publisher',
      key: 'publisher',
      width: 100,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 150,
      align: 'center',
    },
  ];
  
  
  </script>
  
  <style lang="less">
  .full-modal {
    .ant-modal {
      max-width: 100%;
      top: 0;
      padding-bottom: 0;
      margin: 0;
    }
  
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh);
    }
  
    .ant-modal-body {
      flex: 1;
    }
  }
  </style>
  