import { ref, reactive, toRaw, computed, onMounted, onUnmounted } from 'vue';
import { useIdentityStore } from '@/stores/useIdentityStore';
import http from '@/shared/http.service';
import { Uris } from '@/shared/const';
import { from, Subject, EMPTY,of, lastValueFrom, throwError } from 'rxjs';
import { catchError, switchMap, throttleTime } from 'rxjs/operators';
import { Form,notification } from 'ant-design-vue';

export default function useFileAttachment() {
    const store = useIdentityStore();
    //获取附件列表
    const spinning = ref(false);
    const fileViews = ref([]);
    const fileViews$ = new Subject();
    const fileViewsed$ = new Subject();
    const fileViewsSubscription = fileViews$
    .pipe(
        switchMap(requestModel => {
        spinning.value = true;
        return http.post(Uris.Files, requestModel);
        }),
        catchError((err, caught) => {
        spinning.value = false;
        return caught;
        })
    )
    .subscribe(result => {
        spinning.value = false;
        fileViews.value = result?.map(p => ({
            uid: p.id,
            name: p.displayName,
            status: 'done',
            url: (p.storageName.substr(0,1) != '/' && p.storageName.substr(0,1) != '\\')?`${window.serverConfig.baseUrl}${'/' + p.storageName}`:`${window.serverConfig.baseUrl}${p.storageName}`,
            storageName: p.storageName,
            category: p.category,
            relationModule: p.relationModule,
            relationId: p.relationId,
            stamp: p.stamp
          }));
        fileViewsed$.next(fileViews.value);  
    });
    const searchFileViews= relationId => {
        const requestModel = {
            relationId: relationId,           
        };      
        fileViews$.next(requestModel);
    };

    //上传附件
    const uploadingFile = ref(false);
    const fileUploaded$ = new Subject();
    const fileUpload$ = new Subject();
    const fileUploadSubscription = fileUpload$.pipe(
        switchMap(formData => {
            uploadingFile.value = true; 
            return http.upload(Uris.UploadFiles,formData);
        }),
        catchError((err, caught) => {
            notification.error({
                message: '错误',
                description: err.message,
                duration: 2,
            });
            uploadingFile.value = false;
            return caught;
        })
    ).subscribe(importDate => {
        uploadingFile.value = false;
        fileUploaded$.next(importDate);
    });

    const fileUpload = formData => { 
        fileUpload$.next(formData);
    };
    const currentUser = computed(() => store.currentUser);
    const businessOption = computed(() => store.businessOption);

    onUnmounted(() => {
        fileUploadSubscription.unsubscribe();
        fileViewsSubscription.unsubscribe();
    });

    return{ 
        fileUploaded$,
        fileUpload,
        currentUser,
        fileViewsed$,
        searchFileViews
    };
}