<template>
 <div class="th-menu-wrapper">
        <div class="th-menu-area text-center"><button class="th-menu-toggle"><i class="fal fa-times"></i></button>
            <div class="mobile-logo"><a href="index"><img src="assets/img/logo.png" alt="安可控"></a></div>
            <div class="th-mobile-menu">
                <ul>
                    <li class="active"><a href="index">网站首页</a></li>

                    <li class="menu-item-has-children"><a href="#">解决方案</a>
                        <ul class="sub-menu">
                            <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                            <li><a href="solution2.html">智慧安防解决方案</a></li>
                            <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                            <li><a href="solution3.html">智慧工地解决方案</a></li>
                            <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                            <li><a href="solution5.html">智能两票解决方案</a></li>
                            <li><a href="solution6.html">智慧外包解决方案</a></li>
                            <li><a href="solution7.html">双重预控智能管理方案</a></li>
                            <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                            <li><a href="solution9.html">智能锁控解决方案</a></li>
                            <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                            <li><a href="solution11.html">设备缺陷智能分析解决方案</a></li>
                            <li><a href="solution12.html">在线考试管理解决方案</a></li>
                            <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                        </ul>
                    </li>
                    <li class="menu-item-has-children"><a href="#">产品与服务</a>
                        <ul class="sub-menu ">
                            <li> <strong>基础模块</strong>
                                                            <a href="product1.html">生产任务管理</a>
                                                            <a href="product2.html">设备管理</a>
                                                            <a href="product3.html">运行管理</a>
                                                            <a href="product4.html">安全管理</a>
                                                            <a href="product5.html">班组管理</a>
                                                            <a href="product6.html">物资管理</a>
                                                            <a href="product7.html">问题管理</a>
                                                            <a href="product8.html">在线考试管理</a>
                                                            <a href="product9.html">职业健康管理</a>
                                                            <a href="product10.html">标准制度管理</a>
                                                            <a href="product11.html">案例库管理</a>
                            </li>
                            <li> <strong>增值服务</strong>
                                                           <a href="product12.html">智能两票管理</a>
                                    <a href="product13.html">智慧外包管理</a>
                                    <a href="product14.html">智能点检管理</a>
                                  <a href="product15.html">全员绩效管理</a>
                                    <a href="product16.html">风险评估管理</a>
                                    <a href="product17.html">安全生产责任制</a> 
                                                            <strong>特色模块</strong>
                                                            <a href="product18.html">AI视频分析及预警</a>
                                                            <a href="product19.html">实时定位和监控</a>
                                                            <a href="product20.html">智能门禁管理</a>
                                                            <a href="product21.html">智能锁控管理</a>
                                                            <a href="product22.html">智能穿戴设备</a>
                                                            <a href="product23.html">数据采集管理</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="cooperation.html">商业合作</a></li>
                    <li><a href="news">新闻动态</a></li>
                    <li><a href="about.html">关于我们</a></li>
                </ul>
            </div>
        </div>
    </div>
    <header class="th-header header-layout7">
        <div class="header-top">
            <div class="container">
                <div class="header-top-inner">
                    <div class="row justify-content-center justify-content-md-between align-items-center gy-2">
                        <div class="col-auto d-none d-md-block">
                            <div class="header-links d-none d-md-block">
                                <ul>
                                    <li><i class="fal fa-phone"></i><a href="tel:4007059928">400-705-9928</a></li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="header-links">
                                <ul>

                                    <li>
                                        <div class="header-social">
                                            <a href="about.html"><i class="fab fa-weibo"></i></a>
                                            <a href="about.html"><i class="fab fa-weixin"></i></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sticky-wrapper">
            <div class="sticky-active">
                <div class="menu-area">
                    <div class="container">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-auto">
                                <div class="logo-style1"><a href="index"><img src="assets/img/logo.png"
                                            alt="img"></a></div>
                            </div>
                            <div class="col-auto">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <nav class="main-menu d-none d-lg-inline-block">
                                            <ul>
                                                <li><a href="index">网站首页</a></li>
                                                <li class="menu-item-has-children "><a href="#">解决方案</a>
                                                   <ul class="sub-menu">
                                    <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                                    <li><a href="solution2.html">智慧安防高效解决方案</a></li>
                                    <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                                    <li><a href="solution3.html">智慧工地一体解决方案</a></li>
                                    <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                                    <li><a href="solution5.html">智能两票综合解决方案</a></li>
                                    <li><a href="solution6.html">智慧外包管理解决方案</a></li>
                                    <li><a href="solution7.html">双重预控智能管理方案</a></li>
                                    <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                                    <li><a href="solution9.html">智能锁控集成解决方案</a></li>
                                    <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                                    <li><a href="solution11.html">设备缺陷智能分析方案</a></li>
                            <li><a href="solution12.html">在线考试管理解决方案</a></li>
                            <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                                </ul>
                                                </li>
                                                <li class="menu-item-has-children "><a href="#">产品与服务</a>
                                                    <ul class="sub-menu submenurow">
                                                        <li> <strong>基础模块</strong>
                                                            <a href="product1.html">生产任务管理</a>
                                                            <a href="product2.html">设备管理</a>
                                                            <a href="product3.html">运行管理</a>
                                                            <a href="product4.html">安全管理</a>
                                                            <a href="product5.html">班组管理</a>
                                                            <a href="product6.html">物资管理</a>
                                                            <a href="product7.html">问题管理</a>
                                                            <a href="product8.html">在线考试管理</a>
                                                            <a href="product9.html">职业健康管理</a>
                                                            <a href="product10.html">标准制度管理</a>
                                                            <a href="product11.html">案例库管理</a>
                                                        </li>
                                                        <li> <strong>增值服务</strong>
                                                           <a href="product12.html">智能两票管理</a>
                                    <a href="product13.html">智慧外包管理</a>
                                    <a href="product14.html">智能点检管理</a>
                                  <a href="product15.html">全员绩效管理</a>
                                    <a href="product16.html">风险评估管理</a>
                                    <a href="product17.html">安全生产责任制</a> 
                                                            <strong>特色模块</strong>
                                                            <a href="product18.html">AI视频分析及预警</a>
                                                            <a href="product19.html">实时定位和监控</a>
                                                            <a href="product20.html">智能门禁管理</a>
                                                            <a href="product21.html">智能锁控管理</a>
                                                            <a href="product22.html">智能穿戴设备</a>
                                                            <a href="product23.html">数据采集管理</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a href="cooperation.html">商业合作</a></li>
                                                <li class="active"><a href="news">新闻动态</a></li>
                                                <li><a href="about.html">关于我们</a></li>
                                            </ul>
                                        </nav><button type="button" class="th-menu-toggle d-inline-block d-lg-none"><i
                                                class="far fa-bars"></i></button>
                                    </div>
                                    <div class="col-auto d-none d-xl-block">
                                        <div class="header-button"><a href="about.html"
                                                class="th-btn style3 ml-25">联系我们<i
                                                    class="fas fa-arrow-right ms-2"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>


 <main class="fix">


   <div class="breadcumb-wrapper productbreadcumb" data-bg-src="assets/img/bg/breadcumb-bg2.jpg"
     style="background-image: url(assets/img/bg/breadcumb-bg2.jpg);">
     <div class="container">
       <div class="breadcumb-content">
         <h1 class="breadcumb-title">新闻动态</h1>
         <ul class="breadcumb-menu">
           <li><a href="index">首页</a></li>
           <li>新闻动态</li>
         </ul>
       </div>
     </div>
   </div>

   <section class="blog-post-area">

     <div class="container">

       <div class="row justify-content-center" >
        <div class="col-md-6 col-xl-4"  v-for="item in newsList" :key="item.id">
           <div class="th-blog blog-single style2">
             <div class="blog-img">
              <a href="#" @click.prevent="goToNewsView(item.id)">
                <div style="width: 800px;height: 300px; "> <img :src="`${item.cardImage}`" alt=""
                  style="width: 100%; height: 100%; position: absolute; ">
                </div>
              </a>
            </div>
             <div class="blog-content">
               <div class="blog-date"><span>{{ item.day }}</span>{{ item.month }} </div>
               <h4 class="news-title">{{ item.title }}</h4>
               <div class="blog-meta">{{ item.intro }}</div>
               <a href="#" @click.prevent="goToNewsView(item.id)" class="link-btn">浏览详情<i class="flaticon-right-arrow"></i>
               </a>
             </div>
           </div>
         </div>

         

       </div>





       <div class="pagination-wrap mt-30">
         <nav aria-label="Page navigation example">
           <!-- <ul class="pagination list-wrap">
             <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-left"></i></a></li>
             <li class="page-item active"><a class="page-link" href="#">1</a></li>
             <li class="page-item"><a class="page-link" href="#">2</a></li>
             <li class="page-item"><a class="page-link" href="#">3</a></li>
             <li class="page-item"><a class="page-link" href="#">4</a></li>
             <li class="page-item next-page"><a class="page-link" href="#"><i
                   class="fas fa-angle-double-right"></i></a></li>
           </ul> -->
           <ul class="pagination list-wrap">
                <li class="page-item"><a class="page-link" href="#" @click.prevent="goToPreviousPage()"><i
                      class="fas fa-angle-double-left"></i></a></li> 
                <li v-for="(index) in pageList.totalPageCount" :key="index"
                  :class="{ active: pageList.current === index }" @click.prevent="getNewsList(index)">
                  <a class="page-link" href="#">{{ index }}</a>
                </li>

                <li class="page-item next-page"><a class="page-link" href="#" @click.prevent="goToNextPage()"><i
                      class="fas fa-angle-double-right"></i></a></li>
              </ul>
         </nav>
       </div>
     </div>
   </section>
 </main>




 <footer class="footer-wrapper footer-layout2" data-bg-src="assets/img/bg/footer2-bg.png">
       <div class="widget-area space-top">
           <div class="container">
               <div class="row justify-content-between">
                   <div class="col-md-6 col-xxl-5 col-xl-4">
                       <div class="widget footer-widget">
                           <div class="th-widget-about style2">
                               <div class="about-logo"><a href="index"><img src="assets/img/logo2.png"
                                           alt="img"></a></div>
                               <p class="about-text">
                                   安可控是一家致力于推动客户实现高质量发展和高水平安全的智能安全管控专家。安可控始终以先进的人工智能、大数据和物联网技术为核心动力，致力于以优质的产品和服务推动企业安全管理升级、智能化改造、经济增长和社会进步。依托于人工智能和数字化技术，安可控专注于为企业提供简单易用、实用管用的智能化产品，帮助客户完成数字化转型，发展新质生产力，实现高质量发展和高水平安全。
                               </p>
                               <div class="footer-info-grid">
                                   <div class="footer-info-wrap">
                                       <h6 class="footer-info-title">咨询热线</h6>
                                       <div class="footer-info"><i class="fas fa-phone"></i> <a href="tel:4007059928"
                                               class="info-box_link">4007059928</a></div>
                                   </div>
                                   <div class="footer-info-wrap">
                                       <h6 class="footer-info-title">联系电话</h6>
                                       <div class="footer-info"><i class="fas fa-phone"></i> <a href="tel:19918869363"
                                               class="info-box_link">19918869363</a></div>
                                   </div>
                               </div>
                               <div class="footer-info-grid">
                                   <div class="footer-info-wrap">
                                       <h6 class="footer-info-title">微信公众号</h6>
                                       <div><img src="assets/img/code.jpg"></div>
                                   </div>
                                   <div class="footer-info-wrap">
                                       <h6 class="footer-info-title">微博官方号</h6>
                                       <div><img src="assets/img/weibo.jpg"></div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="col-md-6 col-xl-auto">
                       <div class="widget widget_nav_menu footer-widget">
                           <h3 class="widget_title">解决方案</h3>
                           <div class="menu-all-pages-container">
                               <ul class="menu">
                                   <li><a href="solution1.html">安全生产智慧管控方案</a></li>
                                   <li><a href="solution2.html">智慧安防高效解决方案</a></li>
                                   <li><a href="solution2b.html">安健环数字化管理解决方案</a></li>
                                   <li><a href="solution3.html">智慧工地一体解决方案</a></li>
                                   <li><a href="solution4.html">风机智慧定检解决方案</a></li>
                                   <li><a href="solution5.html">智能两票综合解决方案</a></li>
                                   <li><a href="solution6.html">智慧外包管理解决方案</a></li>
                                   <li><a href="solution7.html">双重预控智能管理方案</a></li>
                                   <li><a href="solution8.html">作业安全智能管控解决方案</a></li>
                                   <li><a href="solution9.html">智能锁控集成解决方案</a></li>
                                   <li><a href="solution10.html">有限空间作业智能管控方案</a></li>
                                   <li><a href="solution11.html">设备缺陷智能分析方案</a></li>
                                   <li><a href="solution12.html">在线考试管理解决方案</a></li>
                                   <li><a href="solution13.html">智慧食堂一体化解决方案</a></li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   <div class="col-md-6 col-xl-auto">
                       <div class="widget widget_nav_menu footer-widget">
                           <h3 class="widget_title">基础服务</h3>
                           <div class="menu-all-pages-container">
                               <ul class="menu">
                                   <li><a href="product1.html">生产任务管理</a></li>
                                   <li><a href="product2.html">设备管理</a></li>
                                   <li><a href="product3.html">运行管理</a></li>
                                   <li><a href="product4.html">安全管理</a></li>
                                   <li><a href="product5.html">班组管理</a></li>
                                   <li><a href="product6.html">物资管理</a></li>
                                   <li><a href="product7.html">问题管理</a></li>
                                   <li><a href="product8.html">在线考试管理</a></li>
                                   <li><a href="product9.html">职业健康管理</a></li>
                                   <li><a href="product10.html">标准制度管理</a></li>
                                   <li><a href="product11.html">案例库管理</a></li>
                               </ul>
                           </div>
                       </div>

                   </div>
                   <div class="col-md-6 col-xl-auto">
                       <div class="widget widget_nav_menu footer-widget">
                           <h3 class="widget_title">增值服务</h3>
                           <div class="menu-all-pages-container">
                               <ul class="menu">
                                   <li><a href="product12.html">智能两票管理</a></li>
                                   <li><a href="product13.html">智慧外包管理</a></li>
                                   <li><a href="product14.html">智能点检管理</a></li>
                                   <li><a href="product15.html">全员绩效管理</a></li>
                                   <li><a href="product16.html">风险评估管理</a></li>
                                   <li><a href="product17.html">安全生产责任制</a> </li>
                               </ul>
                           </div>
                       </div>
                       <div class="widget widget_nav_menu footer-widget">
                           <h3 class="widget_title">特色模块</h3>
                           <div class="menu-all-pages-container">
                               <ul class="menu">
                                   <li><a href="product18.html">AI视频分析及预警</a></li>
                                   <li><a href="product19.html">实时定位和监控</a></li>
                                   <li><a href="product20.html">智能门禁管理</a></li>
                                   <li><a href="product21.html">智能锁控管理</a></li>
                                   <li><a href="product22.html">智能穿戴设备</a></li>
                                   <li><a href="product23.html">数据采集管理</a></li>
                               </ul>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="copyright-wrap">
           <div class="container">
               <div class="row justify-content-between align-items-center">
                   <div class="col-md-6">
                       <p class="copyright-text">Copyright © 2024 <a href="index">湖南安可控科技有限公司</a> All Rights
                           Reserved.
                       </p>
                   </div>
                   <div class="col-md-6 text-end d-none d-md-block">
                       <div class="footer-links">
                           <ul>
                               <li><a href="about.html">京ICP备19044307号</a></li>

                           </ul>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </footer>


 <div class="scroll-top"><svg class="progress-circle svg-content" width="100%" height="100%" viewbox="-1 -1 102 102">
     <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
       style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;">
     </path>
   </svg></div>




</template>

<script setup>
import { onBeforeUnmount, ref, shallowRef, onMounted, onUnmounted, reactive } from 'vue';
import http from '@/shared/http.service'; 
import { Uris, DefaultConfig } from '@/shared/const';
import { Subject, from, EMPTY } from 'rxjs';
import { catchError, switchMap, throttleTime } from 'rxjs/operators';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
onMounted(() => {

  getNewsList(1);
});
const isActive = ref(false);
const toggleActive = () => {
  isActive.value = !isActive.value;
}

const newsList = ref([]);
const router = useRouter();

const pageList = reactive({
  total: 0,
  current: 1,
  pageSize: 9,
  totalPageCount: 1
});

///上一页
const goToPreviousPage = () => {
  if (pageList.current > 1) {
    pageList.current = pageList.current - 1;
    getNewsList(pageList.current);
  }
};
//下一页
const goToNextPage = () => { 
  if (pageList.current < pageList.totalPageCount) {
    pageList.current = pageList.current + 1;
    getNewsList(pageList.current);
  }
};

///获取新闻列表
const detail$ = new Subject();
const detailSubscription = detail$
  .pipe(
    switchMap((requestModel) => {  
      return http.get(Uris.List, requestModel);
    }),
    catchError((err, caught) => {
      return caught;
    })
  )
  .subscribe(result => { 
    if (!result.hasNextPage) {
      pageList.current = result.totalPageCount > 0 ? result.totalPageCount : 1;
    } else {
      pageList.current = result.pageIndex;
    }
    pageList.totalPageCount = result.totalPageCount;
    pageList.pageSize = result.pageSize;
    pageList.total = result.totalCount;

    result.list.forEach(element => {
      const model = {
        id: element.id,
        title: element.title,
        month: dayjs(element.stamp).format('MM'),
        day: dayjs(element.stamp).format('DD'),
        intro: element.intro,
        cardImage: `${window.serverConfig.baseUrl}/${element.cardImage}`,
      };
      newsList.value.push(model);
    });
  });

const getNewsList = (number) => {
  newsList.value = [];
  const requestModel = {
    pageIndex: number,
    pageSize: pageList.pageSize,
    orderBy: "stamp",
    direction: "Desc",
  };
  detail$.next(requestModel);
};

const goToNewsView = (id) => {
  // router.push({ name: 'newsview', params: { nid: id } });
  //router.push({ path: `/newsview/${id}` });
  window.open(`/newsview?id=${id}`, '_blank');
};


onUnmounted(() => {
  detailSubscription.unsubscribe();
});

</script>

<style>
.blog-post-area {
 padding-bottom: 100px;
 padding-top: 100px;
}

.news-title
{ 
  display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
}
 .style2
 { 
  margin-bottom: 30px!important;
  }
</style>