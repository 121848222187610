<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  provide: {
  },
};
</script>


<style>
#app {
  width: 100%;
  height: 100%;
}

.ant-input {
  height: 32px !important;
  line-height: 32px;
}

.ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
