export const Uris = {
    RequestPasswordToken: window.serverConfig.baseUrl + '/api/Identity/PasswordToken',
    List: window.serverConfig.baseUrl + '/api/Article/List',
    Get: window.serverConfig.baseUrl + '/api/Article/Get',
    Post: window.serverConfig.baseUrl + '/api/Article/Post',
    Put: window.serverConfig.baseUrl + '/api/Article/Put',
    Delete: window.serverConfig.baseUrl + '/api/Article/Delete',
    GetId: window.serverConfig.baseUrl + '/api/Identity/GetId',
    UploadFiles: window.serverConfig.baseUrl + '/api/File/UploadFiles',
    Edit: window.serverConfig.baseUrl + '/api/Article/Edit',
    CurrentUser: window.serverConfig.baseUrl + '/api/Identity/CurrentUser',
    DeleteFile: window.serverConfig.baseUrl + '/api/File/DeleteFile',
  };
  
  export const DefaultConfig = {
    PageSize: 15,
    LargePageSize: 1000,
    ChunkSize: 28 * 1024 * 1024, //28 MB
  };
  
  export const ErrorMessage = {
    801: '账号已存在',
    802: '账户不存在',
    803: '邮箱不存在',
    804: '用户名或者密码错误',
    805: '需要二次验证',
    806: '原密码不正确',
    807: '手机号码不存在',
    808: '验证码已过期',
    809: '验证码错误',
    900: '账户已绑定管理员',
    901: '微信已绑定用户账号',
    902: '用户标识不能为空',
    903: '文件不存在',
    904: '管理员密码错误',
    905: '无效的编码格式',
    906: '没有文件内容，请求无效',
    907: 'Missing content-type boundary',
    908: '账户未关联微信',
    909: '账户未绑定管理员',
    910: '账户未开启',
    911: '文件大小受限制',
  };
  