import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import Antd from "ant-design-vue";
//import 'tailwindcss/tailwind.css';
import "animate.css/animate.css";
//import 'ant-design-vue/dist/antd.less';
import "./index.css";
import "./assets/css/app.min.css";
//import './assets/css/animate.min.css';
//import './assets/css/magnific-popup.css';
import "./assets/css/fontawesome.min.css";
import "./assets/css/style.css";
//import './assets/css/odometer.css';
//import './assets/css/swiper-bundle.css';
//import './assets/css/aos.css';
//import './assets/css/default.css';
//import './assets/css/main.css';
import "./assets/css/akk.css";
import { createPinia } from "pinia";

createApp(App)
.use(createPinia())
.use(router)
.use(Antd)
.mount("#app");
