<template>
    <div class="clearfix" v-if="fileType === 'image'">
      <a-upload v-model:file-list="this.fileAttachments" name="file" :accept="'image/png,image/jpeg,image/gif,image/bmp'"
        :multiple="isMultiple" :before-upload="beforeUpload" @remove="handleRemove" list-type="picture-card"
        @preview="previewPhoto">
        <div v-if="this.fileAttachments?.length < maxLength">
          <plus-outlined />
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
      <a-modal :open="previewVisible" :footer="null" @cancel="cancelPreview">
        <img alt="" style="width: 100%;" :src="previewImage" />
      </a-modal>
    </div>
    <div v-else>
      <a-upload v-model:file-list="this.fileAttachments" name="file" :multiple="true" :before-upload="beforeUpload"
        @remove="handleRemove">
        <a-button>
          <upload-outlined></upload-outlined>
          选择文件
        </a-button>
      </a-upload>
    </div>
  </template>
  <script>
  import { defineComponent } from 'vue';
  import { UploadOutlined, PlusOutlined } from '@ant-design/icons-vue';
  import useFileAttachment from '@/composables/useFileAttachment.js';
  
  export default defineComponent({
    name: 'file-upload',
    emits: ['upload-complete', 'add-file', 'del-file'],
    props: {
      maxLength: { type: Number, required: false, default: 1 },
      fileList: {
        type: Array,
        required: false,
        default: () => { }
      },
      isMultiple: { type: Boolean, required: false, default: false },
      relationId: { type: String, required: true },
      relationModule: { type: String, required: true },
      uploader: { type: String, required: false, default: "" },
      fileType: { type: String, required: false, defailt: "file" } //文件类型(image：图片、file：文件)
    },
    components: {
      UploadOutlined,
      PlusOutlined
    },
    data() {
      return {
        loadFile: false,//是否加载已上传文件
        previewVisible: false,
        previewImage: null,
        fileAttachments: [],
        removedAttachments: [],
        fileUploadedSubscription: null,
        fileViewsedSubscription: null,
      };
    },
    setup() {
      const {
        fileUploaded$,
        fileUpload,
        currentUser,
        fileViewsed$,
        searchFileViews
      } = useFileAttachment();
  
      return {
        fileUploaded$,
        fileUpload,
        currentUser,
        fileViewsed$,
        searchFileViews
      };
    },
    watch: {
      fileList(newVal, oldVal) {
        // console.log(newVal)
        // console.log(oldVal)
        // console.log(this.fileAttachments)
        // console.log(this.loadFile)
        //解决fileList数据延时 造成图片无法显示的问题 监视fileList 有值时重新赋值 通过loadFile标志 只加载页面时执行一次
        if (newVal.length > 0 && newVal.every(item => item.id)) {
          this.loadFile = false;
          this.fileAttachments = newVal?.map(p => ({
            uid: p.id,
            name: p.displayName,
            status: 'done',
            url: (p.storageName.substr(0, 1) != '/' && p.storageName.substr(0, 1) != '\\') ? `${window.serverConfig.baseUrl}${'/' + p.storageName}` : `${window.serverConfig.baseUrl}${p.storageName}`,
            storageName: p.storageName,
            category: p.category,
            relationModule: p.relationModule,
            relationId: p.relationId,
            stamp: p.stamp
          }));
        }
        else if (newVal.length == 0 && this.fileAttachments.length > 0 && oldVal.length == 0) {
          this.fileAttachments = []
        }
        // console.log(this.fileAttachments)
        // else if (newVal.length == 0 && oldVal.length == 0) {
        //   this.loadFile = false;
        //   this.fileAttachments = [];
        // }
      },
  
    },
    methods: {
      async previewPhoto(file) {
        if (!file.url && !file.preview) {
          this.getBase64Str(file.originFileObj, base64Url => {
            file.preview = base64Url;
            this.previewImage = file.preview;
            this.previewVisible = true;
          });
        }
        else {
          this.previewImage = file.url || file.preview;
          this.previewVisible = true;
        }
      },
      cancelPreview() {
        this.previewVisible = false;
      },
      getBase64Str(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      },
      beforeUpload(fileItem, fileList) {
        // console.log(fileList);
        this.loadFile = true;
        fileItem.status = 'added';
        this.fileAttachments = [...(this.fileAttachments || []), fileItem];
        // this.fileAttachments = []
        // console.log(this.fileAttachments);
        this.$emit('add-file', fileItem);
        return false;
      },
      handleRemove(file) {
        // console.log(file);
        // console.log(this.fileAttachments)
        this.loadFile = true;
        if (file.status !== 'added') {
          this.removedAttachments = [...this.removedAttachments, file];
        }
        // console.log(this.removedAttachments)
        this.$emit('del-file', file);
      },
      refreshFile() {
        this.searchFileViews(this.relationId);
      },
      uploadFile() {
        const formData = new FormData();
        this.fileAttachments?.forEach(file => {
          if (file.status === 'added') {
            formData.append('files', file.originFileObj, file.name);
          }
        });
  
        let delFileIds = "";
        // console.log(this.removedAttachments)
        if (this.removedAttachments?.length > 0) {
          this.removedAttachments.forEach(item => {
            delFileIds += ',' + item.uid;
          });
          delFileIds = delFileIds.substring(1);
        }
  
        formData.append('relationId', this.relationId);
        formData.append("relationModule", this.relationModule);
        formData.append("uploader", this.uploader ?? this.currentUser.id);
        formData.append('delFileIds', delFileIds);
        this.fileUpload(formData);
      }
    },
    mounted() {
      if (this.fileList?.length > 0) {
        this.loadFile = true;
        this.fileAttachments = this.fileList?.map(p => ({
          uid: p.id,
          name: p.displayName,
          status: 'done',
          url: (p.storageName.substr(0, 1) != '/' && p.storageName.substr(0, 1) != '\\') ? `${window.serverConfig.baseUrl}${'/' + p.storageName}` : `${window.serverConfig.baseUrl}${p.storageName}`,
          storageName: p.storageName,
          category: p.category,
          relationModule: p.relationModule,
          relationId: p.relationId,
          stamp: p.stamp
        }));
      }
  
      const thisref = this;
      this.fileUploadedSubscription = this.fileUploaded$.subscribe((result) => {
        if (result.type === "application/json") {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            const uploadResult = JSON.parse(fileReader.result);
            // console.log(uploadResult);
            thisref.$emit('upload-complete', uploadResult);
          };
          fileReader.readAsText(result);
        }
      });
      this.fileViewsedSubscription = this.fileViewsed$.subscribe((result) => {
        thisref.fileAttachments = result;
      });
    }
  });
  </script>